import img001 from "../assets/images/news/Imagenews-001.jpg";
import img002 from "../assets/images/news/Imagenews-002.jpg";
import img003 from "../assets/images/news/Imagenews-003.jpg";
import img004 from "../assets/images/news/Imagenews-004.webp";
import img005 from "../assets/images/news/Imagenews-005.webp";

export const newsData = [
  {
    id: "001",
    url: "seasonal-service",
    date: { iso: "2023-07-16", human: "16.07.2023" },
    title: "Сезонное обслуживание трактора ДТ-75",
    img: img001,
    alt: "Как проводится сезонное обслуживание трактора",
    tag: "Услуги",
    previewSM:
      "Чтобы выносливая машина долго радовала своей бесперебойной работой, требуется регулярно проводить ТО.",
    previewLG:
      "Трактор ДТ-75 – надежная и практичная техника, незаменимая при работе в различных отраслях промышленности. Чтобы выносливая машина долго радовала своей бесперебойной работой, требуется регулярно проводить ТО. Особенно важно правильно подготовить технику к осенне-зимней или к весенне-летней эксплуатации.",
    body: [
      {
        type: "paragraph",
        content:
          "Трактор ДТ-75 – надежная и практичная техника, незаменимая при работе в различных отраслях промышленности. Чтобы выносливая машина долго радовала своей бесперебойной работой, требуется регулярно проводить ТО. Особенно важно правильно подготовить технику к осенне-зимней или к весенне-летней эксплуатации.",
      },
      { type: "subheader", content: "Перевод трактора на «зимний сезон»" },
      {
        type: "paragraph",
        content:
          "Трактор ДТ-75 начинают готовить к осенне-зимнему сезону, когда среднесуточная температура воздуха опустится ниже +5 С0. Обслуживающему персоналу требуется заменить:",
      },
      {
        type: "list",
        content: [
          "летний тип масла на зимний. Для этого сливают технические жидкости из двигателя, гидравлической системы, узлов ходовой части и силовой передачи.",
          "жидкость из системы охлаждения. Используйте ту, которая при низкой температуре продолжит правильно выполнять свои функции.",
          "фильтры, использующиеся в системе. Часто во время сезонного ТО владельцы спецтехники выполняют замену топливного и воздушного фильтра. ",
        ],
      },
      {
        type: "paragraph",
        content:
          "Механикам необходимо проверить работоспособность термометров и датчиков и правильное перемещение шторок на радиаторе. Уделяется внимание всем агрегатам электрооборудования. У аккумулятора проверяется плотность электролита. Утепляется кабина, двигатель и другие части спецтехники. Проверяется работа автономного отопления. Винт реле-регулятора перемещается в положение «Зима».",
      },
      { type: "subheader", content: "Перевод трактора на летний сезон" },
      {
        type: "paragraph",
        content:
          "После окончания зимнего сезона трактор ДТ-75 требуется подготовить к эксплуатации во время повышенной температуры воздуха. Для этого выполняют следующие процедуры:",
      },
      {
        type: "list",
        content: [
          "Из системы охлаждения сливается жидкость, предназначенная для работы во время минусовых температур. Система охлаждения заполняется водой или специальным летним вариантом тосола.",
          "Все утеплительные чехлы, использующиеся зимой для защиты двигателя и других узлов от низких температур, удаляются и помещаются на хранение до следующего холодного сезона.",
          "На реле-регуляторе устанавливается посезонное положение «Лето». Проверяется плотность электролита в аккумуляторе. Система питания заполняется летним топливом. ",
          "Зимние виды масла заменяются летними. Это необходимо сделать для двигателя, трансмиссии, гидравлической системы и других узлов, где используется техническая жидкость. Включается радиатор для масла.",
        ],
      },
      {
        type: "paragraph",
        content:
          "После окончания зимнего сезона нужно проверить элементы корпуса и других частей трактора на наличие коррозии. Места поражения очищаются от ржавчины, покрываются специальными составами, которые не допустят ее распространения.",
      },
      {
        type: "paragraph",
        content:
          "Подготовить Трактор ДТ-75 к сезону не так сложно, как может показаться. При правильном уходе такая техника способна работать десятки лет. Если вам нужно провести профессиональное ТО или другие виды обслуживания гусеничной техники, воспользуйтесь услугами нашей компании.",
      },
    ],
  },
  {
    id: "002",
    url: "DT-75-advantages",
    date: { iso: "2023-06-20", human: "20.06.2023" },
    title: "Особенности и преимущества ДТ-75",
    img: img002,
    alt: "Особенности и преимущества ДТ-75 ВЗГМ-90",
    tag: "Трактора",
    previewSM:
      "ДТ-75 – гусеничная машина, выпуск которой начался в 1963 году. Трактор обладает большой функциональностью.",
    previewLG:
      "ДТ-75 – гусеничная машина, выпуск которой начался в 1963 году. Трактор обладает большой функциональностью. Имеется возможность установки различного навесного оборудования. Это позволяет превратить трактор в бульдозер, снегоуборочную технику, трубоукладчик, траншейный экскаватор и т. п. С помощью своего мощного двигателя, эффективной трансмиссии и другим особенностям, трактор с легкостью справится с теми задачами, которые будут поставлены перед ним.",
    body: [
      {
        type: "paragraph",
        content:
          "ДТ-75 – гусеничная машина, выпуск которой начался в 1963 году. Трактор обладает большой функциональностью. Имеется возможность установки различного навесного оборудования. Это позволяет превратить трактор в бульдозер, снегоуборочную технику, трубоукладчик, траншейный экскаватор и т. п. С помощью своего мощного двигателя, эффективной трансмиссии и другим особенностям, трактор с легкостью справится с теми задачами, которые будут поставлены перед ним. Техника, которая разрабатывается на нашем заводе, эффективная для работы в российских условиях. ",
      },
      { type: "subheader", content: "Устройство трактора ДТ-75" },
      {
        type: "paragraph",
        content:
          "Перед тем как описывать особенности и преимущества трактора ДТ-75, необходимо изучить его устройство. Модель обладает большим количеством модификаций и выпускается разными производителями. Мы предлагаем своим заказчикам этот трактор со следующими характеристиками:",
      },
      {
        type: "list",
        content: [
          "Двигатель. Турбированный дизель новой конструкции Д-440-22. Обладает увеличенную мощность. Это позволяет использовать трактор для выполнения сложных и трудоемких задач.",
          "Трансмиссия. Трактор оснащается коробкой передач, имеющей 7 передних и 14 задних скоростей. Плавную и быструю остановку обеспечивают плавающие тормоза. ",
          "Металлическая рама. Обновленная модель ДТ-75 отличается жесткой конструкцией, усиленной трубами. Она выдерживает большие нагрузки.",
          "Кабина трактора. Кроме места для водителя, в тракторе имеется еще одно кресло. Кабина дополнительно защищена от шума и пыли. Имеется вентиляция и отопление",
          "Навесное подключение. Все модели тракторов ДТ-75 оснащены гидравлическими навесами. К ним можно подключать плуг, сеялку, картофелесажалку и другое оборудование.",
          "Дополнительное оборудование. Современные модели гусеничной техники оснащены электростартером, подогревателем, GPS-системой, АКБ повышенной емкости и автомагнитолой.",
          "Тип гусениц. Техника комплектуется стальными и резиновыми гусеницами. Стандартная ширина трака 390мм. Размер болотной гусеницы 670 мм. ",
        ],
      },
      {
        type: "paragraph",
        content:
          "Обратите внимание: У нас можно заказать трактор ДТ-75 с улучшенными показателями хода. Реверсный и планетарный редуктор создают условия, позволяющие использовать технику для выполнения бульдозерных работ. ",
      },
      { type: "subheader", content: "Достоинства и недостатки" },
      {
        type: "paragraph",
        content:
          "Как и любая специальная техника, использующая гусеничный ход, трактор ДТ-75 является мощной и функциональной машиной. С ее помощью можно решать задачи сельского хозяйства, дорожного строительства, возведения зданий и коммунального хозяйства. Преимуществами такой модели трактора являются:",
      },
      {
        type: "list",
        content: [
          "Низкое давление на поверхность. Это обеспечивается за счет малой массы трактора. При необходимости его можно использовать даже в качестве болотохода. ",
          "Экономичный расход топлива. Турбина увеличивает крутящий момент. Но объем двигателя, а значит, и расход топлива при этом остается достаточно небольшим. ",
          "Легкость работы и обслуживания. Любой вышедший из строя узел можно заменить на новый. Необходимые запчасти у нас имеются в больших количествах. ",
          "Комфорт рабочего места. Мы большое внимание уделили удобству управления агрегатом. Рабочее место оператора по своему комфорту не отличается от европейских аналогов.  ",
        ],
      },
      {
        type: "paragraph",
        content:
          "Трактор ДТ-75 пользуется популярностью благодаря лучшему соответствию цены и качества на отечественном рынке. Мы держим обратную связь с владельцами техники. Еще несколько лет назад недостатком трактора считался гидравлический насос и генератор. Современные модели оснащаются более износостойкими и долговечными узлами",
      },
      {
        type: "paragraph",
        content:
          "Мы предлагаем обновленные модели трактора ДТ-75. Наша гусеничная техника отличается отличным соотношением цены и качества. Несмотря на то что название модели осталось неизменным, большинство характеристик изменились в лучшую сторону. Повышенная проходимость ходовой части, мощный двигатель и возможность использовать трактор для решения различных задач, сделали ДТ-75 по-настоящему «народным» трактором. ",
      },
    ],
  },
  {
    id: "003",
    url: "varieties-of-tracks",
    date: { iso: "2023-05-23", human: "23.05.2023" },
    title: "Разновидности тракторных гусениц",
    img: img003,
    alt: "Какие бывают разновидности тракторных гусениц",
    tag: "Трактора",
    previewSM:
      "Такая техника способна легко преодолевать неровности пересеченной местности, песчаному и земляному грунту.",
    previewLG:
      "За счет гусениц ДТ-75 обладает повышенной проходимостью. Такая техника способна легко преодолевать неровности пересеченной местности, песчаному и земляному грунту. Но, чтобы добиться максимального эффекта, важно правильно выбрать тип гусениц. Сегодня в продаже можно встретить традиционные стальные траки или резиноармированные гусеницы. На каком варианте остановиться?",
    body: [
      {
        type: "paragraph",
        content:
          "За счет гусениц ДТ-75 обладает повышенной проходимостью. Такая техника способна легко преодолевать неровности пересеченной местности, песчаному и земляному грунту. Но, чтобы добиться максимального эффекта, важно правильно выбрать тип гусениц. Сегодня в продаже можно встретить традиционные стальные траки или резиноармированные гусеницы. На каком варианте остановиться?",
      },
      { type: "subheader", content: "Виды и особенности тракторных гусениц" },
      {
        type: "paragraph",
        content:
          "Гусеница – это лента, состоящая из отдельных звеньев – траков. Они соединяются друг с другом шарнирами. Их функцию выполняют стальные стержни. В зависимости от типа техники применяют разную ширину траков. У болотоходов они значительно шире, чем у обычной сельскохозяйственной техникой. Широкие гусеницы увеличивают пятно контакта. Это позволяет снизить удельное давление на грунт. Что крайне важно при перемещении по заболоченной местности.",
      },
      {
        type: "paragraph",
        content:
          "Металлические гусеницы отличаются от резиновых не только материалом изготовления, но и работой. Для привода ленты из стальных траков используется звездочка, резиновые гусеницы приходят в движение колесом. Во втором случае используется только сила трения. Звездочки у резиноармированных гусеницы являются редкостью.",
      },
      {
        type: "paragraph",
        content:
          "Не стоит думать, что резиновые гусеницы полностью выполнены из резины. Внутри них обязательно имеется армирующий корд. Это увеличивает ресурс траков. При езде по твердой поверхности (асфальт, бетон и др.) резинометаллические гусеницы меньше изнашиваются.",
      },
      {
        type: "subheader",
        content: "Стальные или резиноармированные гусеницы?",
      },
      {
        type: "paragraph",
        content:
          "Если работа ведется в скалистой местности, на почвах, в состав которых входит большое количество твердых включений, лучше использовать тракторы со стальными гусеницами. Для работы в городских условиях предпочтительнее прорезиненные траки. Их преимущества:",
      },
      {
        type: "list",
        content: [
          "меньший износ гусениц",
          "большая долговечность",
          "защита от разрушения покрытия",
        ],
      },
      {
        type: "paragraph",
        content:
          "Обратите внимание: Но у резиновых гусениц имеется один существенный недостаток. При контакте с твердой поверхностью они быстрее разрушаются. Поэтому важно перед каждым выездом трактора проводить проверку его гусениц на износ. При обнаружении дефектов необходимо провести ремонт или замену вышедшего из строя элемента.",
      },
      {
        type: "paragraph",
        content:
          "Кроме того, резина имеет свойство растягиваться. От этого не уберечься даже наличием стального корда. Поэтому в отличие от стальных гусениц, при использовании резиновых необходимо регулярно проводить проверку натяжения. Глубокие порезы приводят к тому, что корд начинает подвергаться коррозии. Со временем стальной каркас резиновых траков может быть полностью разрушен.",
      },
      {
        type: "subheader",
        content: "Когда без стальных гусениц трактору не обойтись?",
      },
      {
        type: "paragraph",
        content:
          "Стальные траки обладают высокой износостойкостью. Это достигается за счет изготовления траков из высокомарганцовистой стали. Выполненные из таких сплавов элементы гусениц обладают повышенной надежностью и стойкостью к повреждениям. В нашей стране долгое время использовали только стальные гусеницы. Затем резинометаллические траки стали устанавливать на технику весом до 13-ти тонн. Сегодня, благодаря созданию все более износостойких типов резины и на более тяжелую технику устанавливают «мягкие» гусеницы. Но, если использовать трактор для работы в гранитном карьере или местах, где нужно перемещаться по участку с торчащими арматурами и другими предметами, способными прорезать резину, лучше воспользоваться техникой со стальными гусеницами.",
      },
      {
        type: "paragraph",
        content:
          "Какой тип гусеницы выбрать зависит от работ, которые будут проводиться техникой и типом местности. Для работы в городе можно использовать стальные траки со специальными накладками. Они защитят асфальт от повреждений. Для решения сельскохозяйственных задач на пересеченной местности с обычным типом грунта подойдут резиновые гусеницы.",
      },
    ],
  },

  {
    id: "004",
    url: "cost-effective-tractor-purchases",
    date: { iso: "2024-02-21", human: "21.02.2024" },
    title:
      "Как выгоднее покупать гусеничные трактора и сельхозтехнику в 2024 г.",
    img: img004,
    alt: "Экономически выгодные способы покупки гусеничных тракторов",
    tag: "Экономия",
    previewSM:
      "В условиях экономического спада, поддержание существующего парка техники становится приоритетом.",
    previewLG:
      "Снижение спроса и падение рынка заставляют аграриев искать более экономные способы обновления техники. Как экономно обновить парк тракторов в 2024 году?",
    body: [
      {
        type: "paragraph",
        content:
          "Статистика за 2023 г неутешительна и показывает падение рынка сельхозтехники в том числе и гусеничных тракторов ДТ – 75 и их семейства, примерно на 20%. Упал выпуск и отгрузка тракторов на 6,5% и 13,3% соответственно, в объемных показателях это 5,8 тыс. шт и 4,6 тыс. шт.",
      },
      {
        type: "paragraph",
        content:
          "Причин, как всегда, несколько — это высокая стоимость кредитных средств, низкие цена на продукцию, ограничения на экспорт.",
      },
      {
        type: "paragraph",
        content:
          "Руководство страны активно включилось в регулирование цен, в результате, темпы роста стоимости продуктов растут умеренно, при этом цены на трактора и прочую сельхозтехнику растут в среднем быстрее.",
      },
      {
        type: "paragraph",
        content:
          "А значит железо дорожает, аграрии начинают экономить в первую очередь на замене оборотных активов – техники. Трактора стареют, изнашиваются, чаще встают на ремонт, снижая производительность.",
      },
      {
        type: "paragraph",
        content: "Что с этим делать в нынешних условиях совершенно непонятно.",
      },
      {
        type: "paragraph",
        content:
          "Каких-либо драйверов роста сектора не видно, ограничения на экспорт остаются в силе, рынки сбыта не расширяются, выборы и поствыборная эйфория не лучшие предпосылки для непопулярных мер в виде ослабления контроля над ценами. А техника будет дорожать.",
      },
      {
        type: "paragraph",
        content:
          "Давление на спрос сохранится, и падение рынка скорее всего продолжится. Минсельхоз в прогнозах настроен на сохранение нынешних тенденций рынка, а значит стагнация и медленное сползание и в 2024 г.",
      },
      {
        type: "paragraph",
        content: "Что делать сельхозпроизводителю? ",
      },
      {
        type: "paragraph",
        content:
          "При недостатке денежных средств,  поддержание существующего парка техники и тракторов в исправном состоянии. А значит текущий и капитальный ремонт трактора. По-прежнему, большой трудяга в полях и самый массовый трактор это ДТ-75. ",
      },
      {
        type: "paragraph",
        content:
          "Капитальный ремонт трактора ДТ-75 в умелых руках — это разбор до самой рамы, устранение поломок, замена основных узлов и агрегатов. И в  результате, вы получаете новый трактор ДТ-75 по цене в 2 раза дешевле нового!  ",
      },
      {
        type: "paragraph",
        content:
          "Мы, например, даем гарантию на трактора после капитального ремонта такую же, как и на новый трактор Дт-75, а именно 1 год или 1000 м.ч.     ",
      },
      {
        type: "paragraph",
        content:
          "В случае,  если очень нужно докупить дополнительную единицу, можно купить б/у трактор Дт-75 на авито, но нужно учитывать что 90 % тракторов на авито это убитые в доску, отработавшие по 30+лет трактора. ",
      },
      {
        type: "paragraph",
        content:
          "Да, можно потом сделать капитальный ремонт Дт-75, но затраты будут выше, т.к. вы покупаете трактор  + делаете капитальный ремонт. ",
      },
      {
        type: "paragraph",
        content:
          "Экономически целесообразней будет сразу купить трактор Дт-75 после капитального ремонта. Он будет дешевле и иметь гарантийное обслуживание аналогичное новому трактору.",
      },
      {
        type: "paragraph",
        content: "Позвоните нам, мы обязательно найдем решение!",
      },
      {
        type: "paragraph",
        content: "Ваш надежный партнер",
      },
      {
        type: "paragraph",
        content: "ООО «ВЗГМ».",
      },
    ],
  },
  {
    id: "005",
    url: "changes-in-utilization-fees-2023",
    date: { iso: "2023-07-15", human: "15.07.2023" },
    title: "Изменения в утилизационных сборах на трактора с 1 июля 2023 г.",
    img: img005,
    alt: "Повышение утилизационного сбора на трактора",
    tag: "Утилизационный сбор",
    previewSM:
      "С 1 июля 2023 года значительно повышена стоимость утилизационного сбора на трактора.",
    previewLG:
      "С новыми утилизационными сборами, что делать сельхозпроизводителям?",
    body: [
      {
        type: "paragraph",
        content:
          "Как известно, с 1 июля 2023 г. правительство резко повысило стоимость утилизационного сбора на трактора, повышение предусмотрено в отношении колесных тракторов мощностью от 30 до 340 л.с. (Постановление Правительства РФ от 06.06.2023 N 938), усугубив и без того не радужное положение дел сельхозпроизводителей.",
      },
      {
        type: "paragraph",
        content:
          "Логика государства понятна, поставить заградительные тарифы для импорта новой и бывшей в употреблении в основном китайской техники, ибо вся иная из недружественных стран по известным причинам доступа только у перекупов по баснословным ценникам.",
      },
      {
        type: "paragraph",
        content:
          "Постановление правительства не изменило порядок взимания утилизационных сборов производителей РФ и Белорусии, таким образом дав нам некое конкурентное преимущество, но с другой стороны в первой половине года 2023 г. иностранная техника завозилась максимальными темпами, и фактически, многие ритейлеры затоварены и по сей день.",
      },
      {
        type: "paragraph",
        content:
          "По самым скромным подсчетам стоимость сбора на иностранный трактор 131-180 л.с. станет дороже на 1 317 900 руб., трактор 181-220 л.с. на 1 481 775 руб.",
      },
      {
        type: "paragraph",
        content:
          "Что делать сельхозпроизводителю? Китайская техника, после повышения утиля стоит космических денег, непонятно, что с запчастями, сервисом, европейский и американский рынок недоступны.",
      },
      {
        type: "paragraph",
        content:
          "Конечно, посмотреть на своих, например, на новый трактор ХТЗ Т-150К мощностью 165 л.с. размер утилизационного сбора по состоянию на август 2023 г составляет 1 749 150 руб.",
      },
      {
        type: "paragraph",
        content:
          "При этом под рукой огромный рынок недорогих запчастей, трактор знаком многим механизаторам, не нужно учиться или переучиваться. ",
      },
      {
        type: "paragraph",
        content:
          "Нет электроники, простой, надежный и недорогой добавляем его высокую ремонтопригодность, получается очень мало достойных конкурентов в этом сегменте. ",
      },
      {
        type: "paragraph",
        content: "Не откладывайте звонок! Цены постоянно растут.",
      },
      {
        type: "paragraph",
        content:
          "У нас вы сможете приобрести новую технику без лишних наценок.",
      },
      {
        type: "paragraph",
        content: "Ваш надежный партнер",
      },
      {
        type: "paragraph",
        content: "ООО «ВЗГМ».",
      },
    ],
  },
];
