import newtractorData from "./newData";
import vzgm90 from "../assets/images/tractors/VZGM-90.jpg";
import vzgm150 from "../assets/images/tractors/VZGM-150.jpg";
import engineIcon from "../assets/icons/engineIcon.svg";
import powerIcon from "../assets/icons/powerIcon.svg";
import speedIcon from "../assets/icons/speedIcon.svg";
import tankIcon from "../assets/icons/tankIcon.svg";

//VZGM-90 base
import vzgm_90_base_1 from "../assets/images/tractors/vzgm-90/base/VZGM-90-1.png";
import vzgm_90_base_2 from "../assets/images/tractors/vzgm-90/base/VZGM-90-2.png";
import vzgm_90_base_3 from "../assets/images/tractors/vzgm-90/base/VZGM-90-3.png";
import vzgm_90_base_4 from "../assets/images/tractors/vzgm-90/base/xconvert.com-2-2.png";
import vzgm_90_base_5 from "../assets/images/tractors/vzgm-90/base/xconvert.com-3-2.png";

//VZGM-150 base
import vzgm_150_base_1 from "../assets/images/tractors/vzgm-150/base/VZGM-150-1.png";
import vzgm_150_base_2 from "../assets/images/tractors/vzgm-150/base/VZGM-150-2.png";
import vzgm_150_base_3 from "../assets/images/tractors/vzgm-150/base/VZGM-150-3.png";
import vzgm_150_base_4 from "../assets/images/tractors/vzgm-150/base/VZGM-150-4.png";

//VZGM-90 otval
import vzgm_90_otval_1 from "../assets/images/tractors/vzgm-90/otval/VZGM-90-4.png";
// import vzgm_90_otval_2 from "../assets/images/tractors/vzgm-90/otval/VZGM-90-5.png";
import vzgm_90_otval_3 from "../assets/images/tractors/vzgm-90/otval/VZGM-90-6.png";
import vzgm_90_otval_4 from "../assets/images/tractors/vzgm-90/otval/VZGM-90-7.png";
import vzgm_90_otval_11 from "../assets/images/tractors/vzgm-90/otval/VZGM-90-11.png";
import vzgm_90_otval_5 from "../assets/images/tractors/vzgm-90/otval/VZGM-90-9.png";
import vzgm_90_otval_6 from "../assets/images/tractors/vzgm-90/otval/VZGM-90-10.png";
//import vzgm_90_otval_7 from "../assets/images/tractors/vzgm-90/otval/1-2-2.png";
import vzgm_90_otval_8 from "../assets/images/tractors/vzgm-90/otval/xconvert.com-4-2.png";
import vzgm_90_otval_12 from "../assets/images/tractors/vzgm-90/otval/xconvert.com-5.png";

//VZGM-150 otval
import vzgm_150_otval_1 from "../assets/images/tractors/vzgm-150/otval/VZGM-150-4.png";
import vzgm_150_otval_2 from "../assets/images/tractors/vzgm-150/otval/VZGM-150-5.png";
import vzgm_150_otval_3 from "../assets/images/tractors/vzgm-150/otval/VZGM-150-6.png";
import vzgm_150_otval_4 from "../assets/images/tractors/vzgm-150/otval/VZGM-150-7.png";

//VZGM-90 special
import vzgm_90_spec_1 from "../assets/images/tractors/vzgm-90/special/VZGM-90-8 (1).png";
import vzgm_90_spec_2 from "../assets/images/tractors/vzgm-90/special/VZGM-90-5.png";
import vzgm_90_spec_3 from "../assets/images/tractors/vzgm-90/special/VZGM-90-5 (1).png";

//VZGM-150 special
import vzgm_150_spec_1 from "../assets/images/tractors/vzgm-150/special/VZGM-150-8.png";
import vzgm_150_spec_2 from "../assets/images/tractors/vzgm-150/special/VZGM-150-9.png";
import vzgm_150_spec_3 from "../assets/images/tractors/vzgm-150/special/VZGM-150-10.png";

import vzgm90p1 from "../assets/images/tractors/vzgm-90/VZGM-90-1.png";
import vzgm90p2 from "../assets/images/tractors/vzgm-90/VZGM-90-2.png";
// import vzgm90p3 from "../assets/images/tractors/vzgm-90/VZGM-90-3.png";

import vzgm150p1 from "../assets/images/tractors/vzgm-150/VZGM-150-1.png";
import vzgm150p2 from "../assets/images/tractors/vzgm-150/VZGM-150-2.png";
import vzgm150p3 from "../assets/images/tractors/vzgm-150/VZGM-150-3.png";
import vzgm150p4 from "../assets/images/tractors/vzgm-150/VZGM-150-4.png";
import vzgm150p5 from "../assets/images/tractors/vzgm-150/VZGM-150-5.png";
import vzgm150p6 from "../assets/images/tractors/vzgm-150/VZGM-150-6.png";
import vzgm150p7 from "../assets/images/tractors/vzgm-150/VZGM-150-7.png";


const tractorData = newtractorData

// const tractorData = [
//   {
//     name: "Гусеничный трактор ДТ-75",
//     shortName: "ДТ-75",
//     url: "DT-75",
//     price: "3 050 000",
//     img: vzgm_90_base_1,
//     inStock: true,
//     catalog: "catalog-dt-75",
//     type: "dt-75",
//     alt: "Гусеничный трактор ВЗГМ-90 – усовершенствованная модель трактора ДТ-75",
//     pageTitle: "Купить бульдозер ДТ-75 без навесного оборудования - ВЗГМ",
//     pageDescription:
//       "Трактор ДТ-75 без навесного оборудования предназначен для широкого спектра сельхоз работ. Может быть представлен в модификации болотник, торфяник с болотоходными гусеницами.",
//     gallery: [
//       vzgm_90_base_1,
//       vzgm_90_base_2,
//       vzgm_90_base_3,
//       vzgm_90_base_4,
//       vzgm_90_base_5,
//     ],
//     shortDescription:
//       "ВЗГМ 90 - усовершенствованная модель классического классического ДТ 75, который был очень популярен в СССР. ",
//     metaDescription:
//       "Купить ДТ 75. Усовершенствованная модель трактор ВЗГМ 90 классического ДТ 75, который был очень популярен в СССР.",
//     promo: [
//       {
//         name: "1 год гарантии",
//         description: "производителя",
//       },
//       {
//         name: "ПОГРУЗКА",
//         description: "в подарок",
//       },
//       {
//         name: "ТЕХПОДДЕРЖКА",
//         description: "онлайн",
//       },
//     ],
//     keySpecs: [
//       {
//         name: "Engine",
//         tagImg: engineIcon,
//         description: "А-41 (Д-440-22)",
//       },
//       {
//         name: "Power",
//         tagImg: powerIcon,
//         description: "90/95/98 л.с.",
//       },
//       {
//         name: "Speed",
//         tagImg: speedIcon,
//         description: "5,3…11,2 км/ч",
//       },
//       {
//         name: "Tank",
//         tagImg: tankIcon,
//         description: "175 г/л.с.ч.",
//       },
//     ],
//     description: [
//       {
//         type: "paragraph",
//         content:
//           "Новый  ДТ-75 имеет некоторые конструкторские и дизайнерские отличия. Для ее создания использовались самые современные композитные материалы.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "На тракторе установлен улучшенный двигатель с повышенными техническими характеристиками. Каркас кабины трактора стал более безопасным, а сама она стала более комфортной. Капот машины сделан из стеклопластика и откидывается.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Глушитель и выхлопная труба расположены на передней стойке кабины. Также разработана и применена новая расцветка трактора: бело-голубая, но есть модели тракторов синего цвета.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Простота конструкции, высокая ремонтопригодность гусеничного движителя и отличная проходимость, позволяют снизить стоимость владения, сберечь структуру почвы и повысить урожайность.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Новый трактор ДТ-75 используется в сельском хозяйстве, строительстве, транспортных работах и тд., его легко ремонтировать. Опытный ремонтник без труда справиться с любой поломкой, тем более что к нему подходят запчасти от предшественника.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Существует множество заводских модификаций трактора,  поэтому можно удовлетворить требования любого заказчика. Он может работать как в условиях города, так и  села.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "В зависимости от цели применения машины, она оборудуется гусеницами разного типа:",
//       },
//       {
//         type: "list",
//         content: [
//           "Для сельхозработ и дорожного строительства ставятся звенчатые гусеницы",
//           "Для города и пашни ставятся ленточные, резиноармированные.",
//           "Болотоход использует специальные болотоходные гусеницы, шириной 670 мм. ",
//         ],
//       },
//     ],

//     leasing: [
//       { type: "paragraph", content: "Как получить трактор в лизинг?" },
//       {
//         type: "list",
//         content: [
//           "Выбрать лизинговую компанию",
//           "Оставить заявку",
//           "Подготовить необходимые документы ",
//           "Согласовать условия и подписать договор ",
//           "Получить свою технику",
//         ],
//       },
//       { type: "paragraph", content: "Как правило, для юридических лиц:" },
//       {
//         type: "list",
//         content: [
//           "Заявление-анкета.",
//           "Правоустанавливающие документы",
//           "Копия паспорта директора/учредителей",
//           "Бухгалтерская отчетность",
//           "Решение учредителей об одобрении крупной сделки (если она крупная).",
//         ],
//       },
//       { type: "paragraph", content: "Для индивидуальных предпринимателей:" },
//       {
//         type: "list",
//         content: [
//           "Паспорт РФ. Свидетельство об открытии.",
//           "Свидетельство о постановке на учет в налоговых органах.",
//           "Налоговые декларации с отметкой за 2  последних года.",
//           "Справка из банка об обслуживании счета.",
//         ],
//       },
//     ],
//     characteristics: [
//       { column1: "Тяговый класс", column2: 3 },
//       { column1: "Двигатель", column2: "Д-440-22 (А-41)" },
//       { column1: "Эксплуатационная мощность, л.с.", column2: 95 },
//       {
//         column1:
//           "Частота вращения коленвала при эксплуатационной мощности, об/мин",
//         column2: 1750,
//       },
//       {
//         column1:
//           "Удельный расход топлива при эксплуатационной мощности, г/л.с.ч.",
//         column2: 175,
//       },
//       {
//         column1: "Коэффициент запаса крутящего момента в тяговом режиме, %",
//         column2: 35,
//       },
//       {
//         column1:
//           "Число передач - переднего хода (с ходоуменьшителем и реверс-редуктором)",
//         column2: "7 (23,14)",
//       },
//       {
//         column1:
//           "Число передач - заднего хода (с ходоуменьшителем и реверс-редуктором)",
//         column2: "1 (5,7)",
//       },
//       {
//         column1: "Диапазон скоростей переднего хода, км/ч - основной",
//         column2: "5,3…11,2",
//       },
//       {
//         column1: "Диапазон скоростей переднего хода, км/ч - с ходоуменьшителем",
//         column2: "0,33…11,2",
//       },
//       {
//         column1:
//           "Диапазон скоростей переднего хода, км/ч - с реверс-редуктором",
//         column2: "3,17…11,2",
//       },
//       { column1: "Продольная база, мм", column2: 1612 },
//       { column1: "Колея, мм", column2: 1330 },
//       { column1: "Дорожный просвет, мм", column2: 38 },
//       { column1: "Колея, мм", column2: 1330 },
//       { column1: "Дорожный просвет, мм", column2: 380 },
//       { column1: "Ширина гусениц, мм", column2: "390 или 470" },
//       {
//         column1:
//           "Габаритные размеры, мм - длина (с навесным устройством в транспортном положении)",
//         column2: 4400,
//       },
//       { column1: "Габаритные размеры, мм - ширина", column2: 1850 },
//       { column1: "Габаритные размеры, мм - высота", column2: 2710 },
//       { column1: "Масса эксплуатационная, кг", column2: 6950 },
//       { column1: "Среднее давление на почву, кПа", column2: "41,5" },
//     ],

//     modifications: [
//       { type: "paragraph", content: "Модификации трактора ВЗГМ-90:" },
//       {
//         type: "list",
//         content: [
//           "Фронтальный погрузчик",
//           "Трактор на резиновых гуcеницах",
//           "Торфяник без навесного оборудования",
//           "Снегоочиститель",
//           "Трубоукладчик",
//           "Бульдозер с универсальным отвалом",
//           "Бульдозер с неповоротным отвалом",
//         ],
//       },
//       { type: "paragraph", content: "Варианты комплектации:" },
//       {
//         type: "list",
//         content: [
//           "Реверс-редуктор",
//           "Ходоуменьшитель",
//           "Задняя гидронавесная система",
//           "БНДТ-10, БНДТ-20 – неповоротный бульдозерный отвал",
//           "БПДТ – поворотный бульдозерный отвал",
//           "БУДТ – универсальный бульдозерный отвал с гидроперекосом",
//         ],
//       },
//       {
//         type: "paragraph",
//         content: "Варианты для исполнения гусеничного шасси:",
//       },
//       {
//         type: "list",
//         content: ["Стандартная гусеница 390 мм", "Болотная гусеница 670 мм"],
//       },
//     ],
//   },
//   {
//     name: "Колесный трактор ХТЗ-150",
//     shortName: "ХТЗ-150",
//     url: "XTZ-150",
//     type: "t-150",
//     price: "5 520 000",
//     catalog: "catalog-htz-t150",
//     pageTitle: "ВЗГМ - Купить гусеничный трактор ХТЗ 150",
//     pageDescription:
//       "Купить ХТХ 150 дешево от производителя с гарантией и доставкой. Цена трактора ХТЗ 150 - 5 800 000 руб. ВЗГМ 150 - усовершенствованная модель легендарного трактора ХТЗ 150.",
//     inStock: true,
//     gallery: [
//       vzgm_150_base_1,
//       vzgm_150_base_3,
//       vzgm_150_base_4,
//       vzgm_150_base_2,
//     ],
//     shortDescription:
//       "Купить ХТЗ-150. Усовершенствованная модель трактора ХТЗ-150 - ВЗГМ 150, созданного на базе Харьковского тракторного завода (ХТЗ) в далеком 1974 г. ",

//     img: vzgm_150_base_4,
//     alt: "ВЗГМ-150 – усовершенствованная модель трактора ХТЗ-150",
//     promo: [
//       {
//         name: "1 год гарантии",
//         description: "производителя",
//       },
//       {
//         name: "ПОГРУЗКА",
//         description: "в подарок",
//       },
//       {
//         name: "ТЕХПОДДЕРЖКА",
//         description: "онлайн",
//       },
//     ],
//     keySpecs: [
//       {
//         name: "Engine",
//         tagImg: engineIcon,
//         description: "ЯМЗ-238 (236)",
//       },
//       {
//         name: "Power",
//         tagImg: powerIcon,
//         description: "176/240 кВт/л.с.",
//       },
//       {
//         name: "Speed",
//         tagImg: speedIcon,
//         description: "max. 30 км/ч",
//       },
//       {
//         name: "Tank",
//         tagImg: tankIcon,
//         description: "248 г/л.с.ч.",
//       },
//     ],
//     description: [
//       {
//         type: "paragraph",
//         content:
//           "ВЗГМ – 150 унаследовал все преимущества предшественника ХТЗ -150, популярность модели обусловлена простотой в обслуживании, значительными показателями производительности, надежностью и консервативными ценами (в том числе и на запчасти).",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Трактор характеризуется как умелый помощник в сельском хозяйстве. Применяется при земельной обработке, сборе и посеве урожая, пахоте, внесении минеральных и органических удобрений, сплошной культивации и транспортных работ с прицепами до 20 тонн. Функциональность техники существенно повышается благодаря широкому ассортименту полунавесного и навесного оснащения, устанавливаемого на нее. ВЗГМ-150 отлично чувствует себя в условиях бездорожья.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Другим достоинством модели является то, что отечественные специалисты уже хорошо изучили конструкцию предшественника. Это позволило существенно удешевить обслуживание.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Несомненным преимуществом трактора является низкая стоимость владения.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Трактор может комплектоваться дизельными двигателями ЯМЗ-236 (132 л.с) или ЯМЗ-238 (176 л.с.). Благодаря этому, трактор имеет высокую производительность и может легко справляться с задачами любой сложности. Кроме того, трактор имеет переднеприводную конструкцию, что позволяет улучшить управляемость на дорогах и повысить маневренность, что, несомненно, является большим преимуществом.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Возможность работы на магистральных дорогах с твёрдым покрытием за счёт колёсного хода и обеспечения габарита по ширине менее 2,5 метра.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Универсальность. Увеличенная база трактора (расстояние между осями передних и задних колёс), установка одинаковых передних и задних колёс увеличенной грузоподъёмности, наличие свободного пространства на задней полураме позволяют существенно увеличить количество агрегатируемых с трактором сельхозмашин",
//       },
//       {
//         type: "list",
//         content: [
//           "Повышение надёжности и долговечности",
//           "Улучшение условий труда",
//           "Повышенные транспортные скорости",
//         ],
//       },
//     ],

//     leasing: [
//       { type: "paragraph", content: "Как получить трактор в лизинг?" },
//       {
//         type: "list",
//         content: [
//           "Выбрать лизинговую компанию",
//           "Оставить заявку",
//           "Подготовить необходимые документы ",
//           "Согласовать условия и подписать договор ",
//           "Получить свою технику",
//         ],
//       },
//       { type: "paragraph", content: "Как правило, для юридических лиц:" },
//       {
//         type: "list",
//         content: [
//           "Заявление-анкета.",
//           "Правоустанавливающие документы",
//           "Копия паспорта директора/учредителей",
//           "Бухгалтерская отчетность",
//           "Решение учредителей об одобрении крупной сделки (если она крупная).",
//         ],
//       },
//       { type: "paragraph", content: "Для индивидуальных предпринимателей:" },
//       {
//         type: "list",
//         content: [
//           "Паспорт РФ. Свидетельство об открытии.",
//           "Свидетельство о постановке на учет в налоговых органах.",
//           "Налоговые декларации с отметкой за 2  последних года.",
//           "Справка из банка об обслуживании счета.",
//         ],
//       },
//     ],
//     characteristics: [
//       { column1: "Тяговый класс", column2: 3 },
//       { column1: "Двигатель", column2: " ЯМЗ-238 (236)" },
//       {
//         column1: "Эксплуатационная мощность, л.с.",
//         column2: "176 / 240 кВт /л.с.",
//       },
//       {
//         column1:
//           "Частота вращения коленвала при эксплуатационной мощности, об/мин",
//         column2: 2150,
//       },
//       {
//         column1: "Рабочий объем мотора",
//         column2: "11 л",
//       },
//       {
//         column1: "Полный привод 4х4 ",
//         column2: "есть",
//       },
//       {
//         column1: "Число передач - переднего хода ",
//         column2: "12 ",
//       },
//       {
//         column1:
//           "Число передач - заднего хода (с ходоуменьшителем и реверс-редуктором)",
//         column2: "1 (5,7)",
//       },
//       {
//         column1: "Максимальная скорость вперед",
//         column2: "30 км/час",
//       },
//       {
//         column1: " Максимальная скорость назад ",
//         column2: "10 км/час",
//       },
//       {
//         column1: " Клиренс ",
//         column2: "400 мм",
//       },
//       {
//         column1:
//           "Габаритные размеры, мм - длина (с навесным устройством в транспортном положении)",
//         column2: 6000,
//       },
//       { column1: "Габаритные размеры, мм - ширина", column2: 2406 },
//       { column1: "Габаритные размеры, мм - высота", column2: 3195 },
//       { column1: "Масса эксплуатационная, кг", column2: 8000 },
//       { column1: "Среднее давление на почву, кПа", column2: "130,33" },
//     ],

//     modifications: [
//       { type: "paragraph", content: "Модификации трактора ВЗГМ-150:" },
//       {
//         type: "list",
//         content: [
//           "Оборудован гидравлической навесной системой, валом отбора мощности, прицепной скобой и упряжной серьгой. Установлен предпусковой подогреватель ПБЖ 3000",
//           "Оборудован гидравлическим крюком",
//           "Оборудован бульдозерным отвалом",
//           "Фронтальный погрузчик, оборудован навесным ковшом грузоподъемностью 3 тонны.",
//           "Лесопромышленный, оборудован каркасом безопасности кабины и трелёвочным оборудованием.",
//           "Промышленный вариант, оборудован тягово-сцепным устройством автомобильного типа",
//         ],
//       },
//       { type: "paragraph", content: "Варианты комплектации:" },
//       {
//         type: "list",
//         content: [
//           "Реверс-редуктор",
//           "Ходоуменьшитель",
//           "Задняя гидронавесная система",
//           "БНДТ-10, БНДТ-20 – неповоротный бульдозерный отвал",
//           "БПДТ – поворотный бульдозерный отвал",
//           "БУДТ – универсальный бульдозерный отвал с гидроперекосом",
//         ],
//       },
//       {
//         type: "paragraph",
//         content:
//           "Возможность работы на магистральных дорогах с твёрдым покрытием за счёт колёсного хода и обеспечения габарита по ширине менее 2,5 метра.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Универсальность. Увеличенная база трактора (расстояние между осями передних и задних колёс), установка одинаковых передних и задних колёс увеличенной грузоподъёмности, наличие свободного пространства на задней полураме позволяют существенно увеличить количество агрегатируемых с трактором сельхозмашин",
//       },
//       {
//         type: "list",
//         content: [
//           "Повышение надёжности и долговечности",
//           "Улучшение условий труда",
//           "Повышенные транспортные скорости",
//         ],
//       },
//       {
//         type: "paragraph",
//         content: "",
//       },
//       {
//         type: "paragraph",
//         content: "",
//       },
//     ],
//   },
//   {
//     name: "гусеничный ДТ-75 с отвалом",
//     shortName: "ДТ-75",
//     url: "DT-75-s-otvalom",
//     price: "3 370 000",
//     type: "dt-75",
//     catalog: "catalog-dt-75",
//     img: vzgm90,
//     inStock: true,
//     pageTitle: "Купить бульдозер ДТ-75 с неповоротным отвалом - ВЗГМ",
//     pageDescription:
//       "Продажа трактора, бульдозера ДТ-75 с неповоротным отвалом. Комплектуется разными двигателями мощностью до 100 л.с. Погрузка, доставка.",
//     alt: "Гусеничный трактор ВЗГМ-90 – усовершенствованная модель трактора ДТ-75",
//     gallery: [
//       vzgm_90_otval_11,
//       vzgm_90_otval_12,
//       vzgm_90_otval_6,
//       vzgm_90_otval_5,
//       vzgm_90_otval_1,
//       //vzgm_90_otval_4,
//       //vzgm_90_otval_3,
//       //vzgm_90_otval_7,
//       vzgm_90_otval_8,
//       ,
//     ],
//     shortDescription:
//       "Купить ДТ 75 с отвалом. Усовершенствованная модель трактор ВЗГМ 90 с отвалом классического ДТ 75 с отвалом, который был очень популярен в СССР. ",
//     metaDescription:
//       "Купить Гусеничный трактор ВЗГМ-90 – усовершенствованная модель трактора ДТ-75, который был очень популярен в СССР.",
//     promo: [
//       {
//         name: "1 год гарантии",
//         description: "производителя",
//       },
//       {
//         name: "ПОГРУЗКА",
//         description: "в подарок",
//       },
//       {
//         name: "ТЕХПОДДЕРЖКА",
//         description: "онлайн",
//       },
//     ],

//     keySpecs: [
//       {
//         name: "Engine",
//         tagImg: engineIcon,
//         description: "А-41 (Д-440-22)",
//       },
//       {
//         name: "Power",
//         tagImg: powerIcon,
//         description: "90/95/98 л.с.",
//       },
//       {
//         name: "Speed",
//         tagImg: speedIcon,
//         description: "5,3…11,2 км/ч",
//       },
//       {
//         name: "Tank",
//         tagImg: tankIcon,
//         description: "175 г/л.с.ч.",
//       },
//     ],
//     description: [
//       {
//         type: "paragraph",
//         content:
//           "Бульдозер  ДТ-75 может выпускать с различными типами отвалов:",
//       },
//       {
//         type: "list",
//         content: [
//           "прямой отвал БНДТ- 10",
//           "прямой отвал БНДТ- 20",
//           "поворотный отвал БПДТ",
//           "универсальный отвал БУДТ",
//         ],
//       },
//       {
//         type: "subtitle",
//         content: "Бульдозер с прямым (неповоротным) отвалом БНДТ- 10.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Используется в основном для земляных работ: перемещения грунта, выравнивания поверхности, разработки траншей, в строительстве  - для планирования площадок, уборки снега и мусора, рытья котлованов и тд. ",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Учитывая тяговый класс ДТ-75 , от 3 до 9 тонн,  бульдозер ДТ-75 подходит для разработки грунтов 1 – 2 категории.",
//       },
//       {
//         type: "paragraph",
//         content: "Для БНДТ-10 поставляем следующую комплектацию: ",
//       },
//       {
//         type: "list",
//         content: [
//           "Стальной ковш – основная рабочая часть. Выполняется в трех вариантах – сферической, полусферической или прямой формы. Крепится ковш на передней части рамы бульдозера по обе стороны от двигателя;",
//           "Толкание груза осуществляется брусьями, закрепленными шарнирным способом на раме бульдозера;",
//           "По обе стороны от двигателя расположены два гидроцилиндра, с помощью них ковш приводится в движение при выполнении работ;",
//           "Для предотвращения рассыпания груза во время его перемещения, края отвала прикрыты боковыми щеками;",
//           "Защита радиатора  и гидроцилиндров от повреждений во время работы отвала осуществляются дополнительными щитками.",
//         ],
//       },
//       {
//         type: "subtitle",
//         content: "Бульдозер с прямым (неповоротным) отвалом БНДТ- 20",
//       },
//       {
//         type: "paragraph",
//         content: "Используется для различных  работ в  дорожном строительстве.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Отличие БНДТ-20 от БНДТ-10 заключается в увеличенной ширине отвала. Ширина отвала составляет 2800 мм по сравнению с 2520 мм у БНДТ-10. Для бульдозера с увеличенным отвалом предусмотрены более широкие гусеницы, если сравнивать со стандартным трактором. ",
//       },
//       {
//         type: "paragraph",
//         content: "БНДТ -20 имеет следующие основные характеристики:",
//       },

//       {
//         type: "list",
//         content: [
//           "параметры: ширина отвала — 2800 мм;",
//           "высота — 910 мм;",
//           "величина заглубления 300 мм;",
//           "вес конструкции — 863 кг;",
//           "величина моторесурса до реализации первичного капремонта — 3200 моточасов",
//         ],
//       },
//       {
//         type: "subtitle",
//         content: "Бульдозер с поворотным отвалом БПДТ",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Поворотного отвала БПДТ для ДТ-75 может изменяться, поворачиваясь в обе стороны на 35°. Это позволяет бульдозеры выполнять различные работы с большей производительностью. ",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Поворотный отвал может смещаться вправо и влево относительно хода ДТ-75, подниматься и опускаться, придавая при этом машине определенную мобильность  по сравнению с неповоротным отвалом.",
//       },
//       {
//         type: "paragraph",
//         content: "Конструктивно  БПДТ состоит из: ",
//       },

//       {
//         type: "list",
//         content: [
//           "Нижней и верхней коробки жесткости;",
//           "Вертикальных ребер жесткости;",
//           "Боковой стенки.",
//         ],
//       },
//       {
//         type: "paragraph",
//         content:
//           "Задняя поверхность отвала БПДТ оснащена шаровым гнездом для соединения его с толкающей рамой, на которой имеется шаровая пята. Находящиеся по обе стороны от двигателя гидроцилиндры  приводят в рабочее раму, которая соединяет два верхних и два нижних толкателя. Установка рабочего угла ножей осуществляется  с помощью винтовых раскосов.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Отвал не только может менять рабочий угол, но его можно по-разному установить. Он может стоять перпендикулярно к оси трактора, и продольно;",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Отвал оснащается как металлическими, так и резиновыми ножами, используемыми в дорожно-строительных работах. Определенные модели ножей применяются для разных видов работ: уборочных, планировочных, ландшафтных. Полотно для отвалов, в том числе и поворотных, изготавливается из легированной стали, что существенно увеличивает срок эксплуатации.",
//       },
//       {
//         type: "subtitle",
//         content: "Бульдозер с универсальным отвалом БУДТ",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Отвальные агрегаты, которые могут регулировать углы поворота и перекоса, и имеют шесть степеней свободы, называются универсальными, поворотными отвалами с гидроперекосом.",
//       },
//       {
//         type: "paragraph",
//         content: "Достоинства отвалов БУДТ:",
//       },
//       {
//         type: "list",
//         content: [
//           "Могут изменять поперечные и продольные углы установки  за счет шарнирного типа прикрепления отвала к П-образной раме трактора;",
//           "Могут перемещаться в различных плоскостях с помощью трех гидроцилиндров;",
//           "Гидроцилиндры приводятся в действие и управляются из кабины трактора;",
//         ],
//       },

//       {
//         type: "paragraph",
//         content: "Конструкция:",
//       },
//       {
//         type: "paragraph",
//         content:
//           " Отвал БУДТ выглядит как металлическая сварная конструкция жесткого коробчатого типа, с приваренным к ней лобовым листом из высокопрочной легированной стали. Лист криволинейного профиля состоит из двух частей, скрепленных между собой шарнирами. Такая конструкция делает каждую из частей отвала самостоятельной единицей, которая может изменять свое положение относительно другой части. Это расширяет функциональные возможности универсального отвала по сравнению с отвалами другой конструкции.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Отвал БУДТ имеет меньшую высоту и используется в основном при планировочных работах в перемещении сыпучих строительных материалов, в расчистке дорожного полотна от грязи и снега. ",
//       },
//       {
//         type: "paragraph",
//         content: "Может также использоваться:",
//       },
//       {
//         type: "list",
//         content: [
//           "Как прямой отвал;",
//           "Как поворотный отвал;",
//           "Как поперечный перекос.",
//         ],
//       },
//       {
//         type: "paragraph",
//         content:
//           "Новый бульдозер ДТ-75 имеет конструктивные отличия от своего предшественника - кабина из композитного материала, улучшенная шумо и виброизоляция, система вентиляции обеспечат улучшенную эргономику для  механизатора, а значит повысят его производительность. ",
//       },
//       {
//         type: "paragraph",
//         content: "Бульдозер ДТ 75 может выпускать со следующими двигателями:",
//       },
//       {
//         type: "table",
//         content: [
//           { column1: "А 41", column2: 90, column3: "Алтайский моторный завод" },
//           { column1: "Д-245", column2: 95, column3: "Минский моторный завод" },
//           { column1: "Д-260", column2: 98, column3: "Минский моторный завод" },
//         ],
//       },
//       {
//         type: "paragraph",
//         content:
//           "Приобретая бульдозер ДТ-75 с неповоротным отвалом у производителя ООО «ВЗГМ», вы получаете:",
//       },
//       {
//         type: "list",
//         content: [
//           "сертифицированное качество",
//           "заводскую гарантию",
//           "конкурентные    цены",
//           "доставку в любой регион РФ",
//         ],
//       },
//     ],

//     leasing: [
//       { type: "paragraph", content: "Как получить трактор в лизинг?" },
//       {
//         type: "list",
//         content: [
//           "Выбрать лизинговую компанию",
//           "Оставить заявку",
//           "Подготовить необходимые документы ",
//           "Согласовать условия и подписать договор ",
//           "Получить свою технику",
//         ],
//       },
//       { type: "paragraph", content: "Как правило, для юридических лиц:" },
//       {
//         type: "list",
//         content: [
//           "Заявление-анкета.",
//           "Правоустанавливающие документы",
//           "Копия паспорта директора/учредителей",
//           "Бухгалтерская отчетность",
//           "Решение учредителей об одобрении крупной сделки (если она крупная).",
//         ],
//       },
//       { type: "paragraph", content: "Для индивидуальных предпринимателей:" },
//       {
//         type: "list",
//         content: [
//           "Паспорт РФ. Свидетельство об открытии.",
//           "Свидетельство о постановке на учет в налоговых органах.",
//           "Налоговые декларации с отметкой за 2  последних года.",
//           "Справка из банка об обслуживании счета.",
//         ],
//       },
//     ],
//     characteristics: [
//       { column1: "Тяговый класс", column2: 3 },
//       { column1: "Двигатель", column2: "Д-440-22 (А-41)" },
//       { column1: "Эксплуатационная мощность, л.с.", column2: 95 },
//       {
//         column1:
//           "Частота вращения коленвала при эксплуатационной мощности, об/мин",
//         column2: 1750,
//       },
//       {
//         column1:
//           "Удельный расход топлива при эксплуатационной мощности, г/л.с.ч.",
//         column2: 175,
//       },
//       {
//         column1: "Коэффициент запаса крутящего момента в тяговом режиме, %",
//         column2: 35,
//       },
//       {
//         column1:
//           "Число передач - переднего хода (с ходоуменьшителем и реверс-редуктором)",
//         column2: "7 (23,14)",
//       },
//       {
//         column1:
//           "Число передач - заднего хода (с ходоуменьшителем и реверс-редуктором)",
//         column2: "1 (5,7)",
//       },
//       {
//         column1: "Диапазон скоростей переднего хода, км/ч - основной",
//         column2: "5,3…11,2",
//       },
//       {
//         column1: "Диапазон скоростей переднего хода, км/ч - с ходоуменьшителем",
//         column2: "0,33…11,2",
//       },
//       {
//         column1:
//           "Диапазон скоростей переднего хода, км/ч - с реверс-редуктором",
//         column2: "3,17…11,2",
//       },
//       { column1: "Продольная база, мм", column2: 1612 },
//       { column1: "Колея, мм", column2: 1330 },
//       { column1: "Дорожный просвет, мм", column2: 38 },
//       { column1: "Колея, мм", column2: 1330 },
//       { column1: "Дорожный просвет, мм", column2: 380 },
//       { column1: "Ширина гусениц, мм", column2: "390 или 470" },
//       {
//         column1:
//           "Габаритные размеры, мм - длина (с навесным устройством в транспортном положении)",
//         column2: 4400,
//       },
//       { column1: "Габаритные размеры, мм - ширина", column2: 1850 },
//       { column1: "Габаритные размеры, мм - высота", column2: 2710 },
//       { column1: "Масса эксплуатационная, кг", column2: 6950 },
//       { column1: "Среднее давление на почву, кПа", column2: "41,5" },
//     ],

//     modifications: [
//       { type: "paragraph", content: "Модификации трактора ВЗГМ-90:" },
//       {
//         type: "list",
//         content: [
//           "Фронтальный погрузчик",
//           "Трактор на резиновых гуcеницах",
//           "Торфяник без навесного оборудования",
//           "Снегоочиститель",
//           "Трубоукладчик",
//           "Бульдозер с универсальным отвалом",
//           "Бульдозер с неповоротным отвалом",
//         ],
//       },
//       { type: "paragraph", content: "Варианты комплектации:" },
//       {
//         type: "list",
//         content: [
//           "Реверс-редуктор",
//           "Ходоуменьшитель",
//           "Задняя гидронавесная система",
//           "БНДТ-10, БНДТ-20 – неповоротный бульдозерный отвал",
//           "БПДТ – поворотный бульдозерный отвал",
//           "БУДТ – универсальный бульдозерный отвал с гидроперекосом",
//         ],
//       },
//       {
//         type: "paragraph",
//         content: "Варианты для исполнения гусеничного шасси:",
//       },
//       {
//         type: "list",
//         content: ["Стандартная гусеница 390 мм", "Болотная гусеница 670 мм"],
//       },
//     ],
//   },
//   {
//     name: "колесный ХТЗ-150 с отвалом",
//     shortName: "ХТЗ-150",
//     url: "XTZ-150-s-otvalom",
//     price: "5 900 000",
//     type: "t-150",
//     catalog: "catalog-htz-t150",
//     pageTitle: "Купить бульдозер ХТЗ Т-150 с навесным оборудованием - ВЗГМ",
//     pageDescription:
//       "Продажа бульдозера ХТЗ Т-150. Простой и доступный ХТЗ Т-150 для сельскохозяйственных работ с доставкой в РФ и СНГ.",
//     inStock: true,
//     gallery: [
//       vzgm_150_otval_4,
//       vzgm_150_otval_1,
//       vzgm_150_otval_3,

//       vzgm_150_otval_2,
//     ],
//     shortDescription:
//       "ВЗГМ-150 – усовершенствованная модель трактора ХТЗ-150, созданного на базе Харьковского тракторного завода (ХТЗ) в далеком 1974 г. ",

//     img: vzgm150,
//     alt: "ВЗГМ-150 – усовершенствованная модель трактора ХТЗ-150",
//     promo: [
//       {
//         name: "1 год гарантии",
//         description: "производителя",
//       },
//       {
//         name: "ПОГРУЗКА",
//         description: "в подарок",
//       },
//       {
//         name: "ТЕХПОДДЕРЖКА",
//         description: "онлайн",
//       },
//     ],
//     keySpecs: [
//       {
//         name: "Engine",
//         tagImg: engineIcon,
//         description: "ЯМЗ-238 (236)",
//       },
//       {
//         name: "Power",
//         tagImg: powerIcon,
//         description: "176/240 кВт/л.с.",
//       },
//       {
//         name: "Speed",
//         tagImg: speedIcon,
//         description: "max. 30 км/ч",
//       },
//       {
//         name: "Tank",
//         tagImg: tankIcon,
//         description: "248 г/л.с.ч.",
//       },
//     ],
//     description: [
//       {
//         type: "paragraph",
//         content:
//           "ВЗГМ – 150 унаследовал все преимущества предшественника ХТЗ -150, популярность модели обусловлена простотой в обслуживании, значительными показателями производительности, надежностью и консервативными ценами (в том числе и на запчасти).",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Трактор характеризуется как умелый помощник в сельском хозяйстве. Применяется при земельной обработке, сборе и посеве урожая, пахоте, внесении минеральных и органических удобрений, сплошной культивации и транспортных работ с прицепами до 20 тонн. Функциональность техники существенно повышается благодаря широкому ассортименту полунавесного и навесного оснащения, устанавливаемого на нее. ВЗГМ-150 отлично чувствует себя в условиях бездорожья.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Другим достоинством модели является то, что отечественные специалисты уже хорошо изучили конструкцию предшественника. Это позволило существенно удешевить обслуживание.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Несомненным преимуществом трактора является низкая стоимость владения.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Трактор может комплектоваться дизельными двигателями ЯМЗ-236 (132 л.с) или ЯМЗ-238 (176 л.с.). Благодаря этому, трактор имеет высокую производительность и может легко справляться с задачами любой сложности. Кроме того, трактор имеет переднеприводную конструкцию, что позволяет улучшить управляемость на дорогах и повысить маневренность, что, несомненно, является большим преимуществом.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Возможность работы на магистральных дорогах с твёрдым покрытием за счёт колёсного хода и обеспечения габарита по ширине менее 2,5 метра.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Универсальность. Увеличенная база трактора (расстояние между осями передних и задних колёс), установка одинаковых передних и задних колёс увеличенной грузоподъёмности, наличие свободного пространства на задней полураме позволяют существенно увеличить количество агрегатируемых с трактором сельхозмашин",
//       },
//       {
//         type: "list",
//         content: [
//           "Повышение надёжности и долговечности",
//           "Улучшение условий труда",
//           "Повышенные транспортные скорости",
//         ],
//       },
//     ],

//     leasing: [
//       { type: "paragraph", content: "Как получить трактор в лизинг?" },
//       {
//         type: "list",
//         content: [
//           "Выбрать лизинговую компанию",
//           "Оставить заявку",
//           "Подготовить необходимые документы ",
//           "Согласовать условия и подписать договор ",
//           "Получить свою технику",
//         ],
//       },
//       { type: "paragraph", content: "Как правило, для юридических лиц:" },
//       {
//         type: "list",
//         content: [
//           "Заявление-анкета.",
//           "Правоустанавливающие документы",
//           "Копия паспорта директора/учредителей",
//           "Бухгалтерская отчетность",
//           "Решение учредителей об одобрении крупной сделки (если она крупная).",
//         ],
//       },
//       { type: "paragraph", content: "Для индивидуальных предпринимателей:" },
//       {
//         type: "list",
//         content: [
//           "Паспорт РФ. Свидетельство об открытии.",
//           "Свидетельство о постановке на учет в налоговых органах.",
//           "Налоговые декларации с отметкой за 2  последних года.",
//           "Справка из банка об обслуживании счета.",
//         ],
//       },
//     ],
//     characteristics: [
//       { column1: "Тяговый класс", column2: 3 },
//       { column1: "Двигатель", column2: " ЯМЗ-238 (236)" },
//       {
//         column1: "Эксплуатационная мощность, л.с.",
//         column2: "176 / 240 кВт /л.с.",
//       },
//       {
//         column1:
//           "Частота вращения коленвала при эксплуатационной мощности, об/мин",
//         column2: 2150,
//       },
//       {
//         column1: "Рабочий объем мотора",
//         column2: "11 л",
//       },
//       {
//         column1: "Полный привод 4х4 ",
//         column2: "есть",
//       },
//       {
//         column1: "Число передач - переднего хода ",
//         column2: "12 ",
//       },
//       {
//         column1:
//           "Число передач - заднего хода (с ходоуменьшителем и реверс-редуктором)",
//         column2: "1 (5,7)",
//       },
//       {
//         column1: "Максимальная скорость вперед",
//         column2: "30 км/час",
//       },
//       {
//         column1: " Максимальная скорость назад ",
//         column2: "10 км/час",
//       },
//       {
//         column1: " Клиренс ",
//         column2: "400 мм",
//       },
//       {
//         column1:
//           "Габаритные размеры, мм - длина (с навесным устройством в транспортном положении)",
//         column2: 6000,
//       },
//       { column1: "Габаритные размеры, мм - ширина", column2: 2406 },
//       { column1: "Габаритные размеры, мм - высота", column2: 3195 },
//       { column1: "Масса эксплуатационная, кг", column2: 8000 },
//       { column1: "Среднее давление на почву, кПа", column2: "130,33" },
//     ],

//     modifications: [
//       { type: "paragraph", content: "Модификации трактора ВЗГМ-150:" },
//       {
//         type: "list",
//         content: [
//           "Оборудован гидравлической навесной системой, валом отбора мощности, прицепной скобой и упряжной серьгой. Установлен предпусковой подогреватель ПБЖ 3000",
//           "Оборудован гидравлическим крюком",
//           "Оборудован бульдозерным отвалом",
//           "Фронтальный погрузчик, оборудован навесным ковшом грузоподъемностью 3 тонны.",
//           "Лесопромышленный, оборудован каркасом безопасности кабины и трелёвочным оборудованием.",
//           "Промышленный вариант, оборудован тягово-сцепным устройством автомобильного типа",
//         ],
//       },
//       { type: "paragraph", content: "Варианты комплектации:" },
//       {
//         type: "list",
//         content: [
//           "Реверс-редуктор",
//           "Ходоуменьшитель",
//           "Задняя гидронавесная система",
//           "БНДТ-10, БНДТ-20 – неповоротный бульдозерный отвал",
//           "БПДТ – поворотный бульдозерный отвал",
//           "БУДТ – универсальный бульдозерный отвал с гидроперекосом",
//         ],
//       },
//       {
//         type: "paragraph",
//         content:
//           "Возможность работы на магистральных дорогах с твёрдым покрытием за счёт колёсного хода и обеспечения габарита по ширине менее 2,5 метра.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Универсальность. Увеличенная база трактора (расстояние между осями передних и задних колёс), установка одинаковых передних и задних колёс увеличенной грузоподъёмности, наличие свободного пространства на задней полураме позволяют существенно увеличить количество агрегатируемых с трактором сельхозмашин",
//       },
//       {
//         type: "list",
//         content: [
//           "Повышение надёжности и долговечности",
//           "Улучшение условий труда",
//           "Повышенные транспортные скорости",
//         ],
//       },
//       {
//         type: "paragraph",
//         content: "",
//       },
//       {
//         type: "paragraph",
//         content: "",
//       },
//     ],
//   },
//   {
//     name: "ДТ-75 с навесным оборудованием",
//     shortName: "ДТ-75",
//     url: "DT-75-s-navesnym",
//     price: "3 495 000",
//     type: "dt-75",
//     img: vzgm90,
//     catalog: "catalog-dt-75",
//     inStock: false,
//     pageTitle: "Купить бульдозер ДТ-75 с навесным оборудованием - ВЗГМ",
//     pageDescription:
//       "Продажа трактора ДТ-75 с оборудованием. Простой и доступный ДТ-75 для сельскохозяйственных работ с доставкой в РФ и СНГ.",
//     alt: "Гусеничный трактор ВЗГМ-90 – усовершенствованная модель трактора ДТ-75",
//     gallery: [vzgm_90_spec_1, vzgm_90_spec_3],
//     shortDescription:
//       "Гусеничный трактор ВЗГМ-90 – усовершенствованная модель трактора ДТ-75, который был очень популярен в СССР. ",
//     promo: [
//       {
//         name: "1 год гарантии",
//         description: "производителя",
//       },
//       {
//         name: "ПОГРУЗКА",
//         description: "в подарок",
//       },
//       {
//         name: "ТЕХПОДДЕРЖКА",
//         description: "онлайн",
//       },
//     ],

//     keySpecs: [
//       {
//         name: "Engine",
//         tagImg: engineIcon,
//         description: "А-41 (Д-440-22)",
//       },
//       {
//         name: "Power",
//         tagImg: powerIcon,
//         description: "90/95/98 л.с.",
//       },
//       {
//         name: "Speed",
//         tagImg: speedIcon,
//         description: "5,3…11,2 км/ч",
//       },
//       {
//         name: "Tank",
//         tagImg: tankIcon,
//         description: "175 г/л.с.ч.",
//       },
//     ],
//     description: [
//       {
//         type: "paragraph",
//         content:
//           "Новый ДТ-75 имеет некоторые конструкторские и дизайнерские отличия. Для ее создания использовались самые современные композитные материалы.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "На тракторе установлен улучшенный двигатель с повышенными техническими характеристиками. Каркас кабины трактора стал более безопасным, а сама она стала более комфортной. Капот машины сделан из стеклопластика и откидывается.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Глушитель и выхлопная труба расположены на передней стойке кабины. Также разработана и применена новая расцветка трактора: бело-голубая, но есть модели тракторов синего цвета.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Простота конструкции, высокая ремонтопригодность гусеничного движителя и отличная проходимость, позволяют снизить стоимость владения, сберечь структуру почвы и повысить урожайность.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Трактор используется в сельском хозяйстве, строительстве, транспортных работах и тд. Трактор ВЗГМ-90 легко ремонтировать. Опытный ремонтник без труда справиться с любой поломкой, тем более что к нему подходят запчасти от его предшественника.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "На данный момент есть множество заводских разновидностей трактора. Поэтому можно удовлетворить требования любого заказчика. Он может работать в условиях города или села.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "В зависимости от цели применения машины, она оборудуется гусеницами разного типа:",
//       },
//       {
//         type: "list",
//         content: [
//           "Для сельхозработ и дорожного строительства ставятся звенчатые гусеницы",
//           "Для города и пашни ставятся ленточные, резиноармированные.",
//           "Болотоход использует специальные болотоходные гусеницы, шириной 670 мм. ",
//         ],
//       },
//     ],

//     leasing: [
//       { type: "paragraph", content: "Как получить трактор в лизинг?" },
//       {
//         type: "list",
//         content: [
//           "Выбрать лизинговую компанию",
//           "Оставить заявку",
//           "Подготовить необходимые документы ",
//           "Согласовать условия и подписать договор ",
//           "Получить свою технику",
//         ],
//       },
//       { type: "paragraph", content: "Как правило, для юридических лиц:" },
//       {
//         type: "list",
//         content: [
//           "Заявление-анкета.",
//           "Правоустанавливающие документы",
//           "Копия паспорта директора/учредителей",
//           "Бухгалтерская отчетность",
//           "Решение учредителей об одобрении крупной сделки (если она крупная).",
//         ],
//       },
//       { type: "paragraph", content: "Для индивидуальных предпринимателей:" },
//       {
//         type: "list",
//         content: [
//           "Паспорт РФ. Свидетельство об открытии.",
//           "Свидетельство о постановке на учет в налоговых органах.",
//           "Налоговые декларации с отметкой за 2  последних года.",
//           "Справка из банка об обслуживании счета.",
//         ],
//       },
//     ],
//     characteristics: [
//       { column1: "Тяговый класс", column2: 3 },
//       { column1: "Двигатель", column2: "Д-440-22 (А-41)" },
//       { column1: "Эксплуатационная мощность, л.с.", column2: 95 },
//       {
//         column1:
//           "Частота вращения коленвала при эксплуатационной мощности, об/мин",
//         column2: 1750,
//       },
//       {
//         column1:
//           "Удельный расход топлива при эксплуатационной мощности, г/л.с.ч.",
//         column2: 175,
//       },
//       {
//         column1: "Коэффициент запаса крутящего момента в тяговом режиме, %",
//         column2: 35,
//       },
//       {
//         column1:
//           "Число передач - переднего хода (с ходоуменьшителем и реверс-редуктором)",
//         column2: "7 (23,14)",
//       },
//       {
//         column1:
//           "Число передач - заднего хода (с ходоуменьшителем и реверс-редуктором)",
//         column2: "1 (5,7)",
//       },
//       {
//         column1: "Диапазон скоростей переднего хода, км/ч - основной",
//         column2: "5,3…11,2",
//       },
//       {
//         column1: "Диапазон скоростей переднего хода, км/ч - с ходоуменьшителем",
//         column2: "0,33…11,2",
//       },
//       {
//         column1:
//           "Диапазон скоростей переднего хода, км/ч - с реверс-редуктором",
//         column2: "3,17…11,2",
//       },
//       { column1: "Продольная база, мм", column2: 1612 },
//       { column1: "Колея, мм", column2: 1330 },
//       { column1: "Дорожный просвет, мм", column2: 38 },
//       { column1: "Колея, мм", column2: 1330 },
//       { column1: "Дорожный просвет, мм", column2: 380 },
//       { column1: "Ширина гусениц, мм", column2: "390 или 470" },
//       {
//         column1:
//           "Габаритные размеры, мм - длина (с навесным устройством в транспортном положении)",
//         column2: 4400,
//       },
//       { column1: "Габаритные размеры, мм - ширина", column2: 1850 },
//       { column1: "Габаритные размеры, мм - высота", column2: 2710 },
//       { column1: "Масса эксплуатационная, кг", column2: 6950 },
//       { column1: "Среднее давление на почву, кПа", column2: "41,5" },
//     ],

//     modifications: [
//       { type: "paragraph", content: "Модификации трактора ВЗГМ-90:" },
//       {
//         type: "list",
//         content: [
//           "Фронтальный погрузчик",
//           "Трактор на резиновых гуcеницах",
//           "Торфяник без навесного оборудования",
//           "Снегоочиститель",
//           "Трубоукладчик",
//           "Бульдозер с универсальным отвалом",
//           "Бульдозер с неповоротным отвалом",
//         ],
//       },
//       { type: "paragraph", content: "Варианты комплектации:" },
//       {
//         type: "list",
//         content: [
//           "Реверс-редуктор",
//           "Ходоуменьшитель",
//           "Задняя гидронавесная система",
//           "БНДТ-10, БНДТ-20 – неповоротный бульдозерный отвал",
//           "БПДТ – поворотный бульдозерный отвал",
//           "БУДТ – универсальный бульдозерный отвал с гидроперекосом",
//         ],
//       },
//       {
//         type: "paragraph",
//         content: "Варианты для исполнения гусеничного шасси:",
//       },
//       {
//         type: "list",
//         content: ["Стандартная гусеница 390 мм", "Болотная гусеница 670 мм"],
//       },
//     ],
//   },
//   {
//     name: "ХТЗ-150 с навесным оборудованием",
//     shortName: "ХТЗ-150",
//     url: "XTZ-150-s-navesnym",
//     price: "6 095 000",
//     catalog: "catalog-htz-t150",
//     type: "t-150",
//     inStock: true,
//     gallery: [vzgm_150_spec_1, vzgm_150_spec_2, vzgm_150_spec_3],
//     shortDescription:
//       "ВЗГМ-150 – усовершенствованная модель трактора ХТЗ-150, созданного на базе Харьковского тракторного завода (ХТЗ) в далеком 1974 г. ",

//     img: vzgm150,
//     alt: "ВЗГМ-150 – усовершенствованная модель трактора ХТЗ-150",
//     promo: [
//       {
//         name: "1 год гарантии",
//         description: "производителя",
//       },
//       {
//         name: "ПОГРУЗКА",
//         description: "в подарок",
//       },
//       {
//         name: "ТЕХПОДДЕРЖКА",
//         description: "онлайн",
//       },
//     ],
//     keySpecs: [
//       {
//         name: "Engine",
//         tagImg: engineIcon,
//         description: "ЯМЗ-238 (236)",
//       },
//       {
//         name: "Power",
//         tagImg: powerIcon,
//         description: "176/240 кВт/л.с.",
//       },
//       {
//         name: "Speed",
//         tagImg: speedIcon,
//         description: "max. 30 км/ч",
//       },
//       {
//         name: "Tank",
//         tagImg: tankIcon,
//         description: "248 г/л.с.ч.",
//       },
//     ],
//     description: [
//       {
//         type: "paragraph",
//         content:
//           "ВЗГМ – 150 унаследовал все преимущества предшественника ХТЗ -150, популярность модели обусловлена простотой в обслуживании, значительными показателями производительности, надежностью и консервативными ценами (в том числе и на запчасти).",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Трактор характеризуется как умелый помощник в сельском хозяйстве. Применяется при земельной обработке, сборе и посеве урожая, пахоте, внесении минеральных и органических удобрений, сплошной культивации и транспортных работ с прицепами до 20 тонн. Функциональность техники существенно повышается благодаря широкому ассортименту полунавесного и навесного оснащения, устанавливаемого на нее. ВЗГМ-150 отлично чувствует себя в условиях бездорожья.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Другим достоинством модели является то, что отечественные специалисты уже хорошо изучили конструкцию предшественника. Это позволило существенно удешевить обслуживание.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Несомненным преимуществом трактора является низкая стоимость владения.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Трактор может комплектоваться дизельными двигателями ЯМЗ-236 (132 л.с) или ЯМЗ-238 (176 л.с.). Благодаря этому, трактор имеет высокую производительность и может легко справляться с задачами любой сложности. Кроме того, трактор имеет переднеприводную конструкцию, что позволяет улучшить управляемость на дорогах и повысить маневренность, что, несомненно, является большим преимуществом.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Возможность работы на магистральных дорогах с твёрдым покрытием за счёт колёсного хода и обеспечения габарита по ширине менее 2,5 метра.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Универсальность. Увеличенная база трактора (расстояние между осями передних и задних колёс), установка одинаковых передних и задних колёс увеличенной грузоподъёмности, наличие свободного пространства на задней полураме позволяют существенно увеличить количество агрегатируемых с трактором сельхозмашин",
//       },
//       {
//         type: "list",
//         content: [
//           "Повышение надёжности и долговечности",
//           "Улучшение условий труда",
//           "Повышенные транспортные скорости",
//         ],
//       },
//     ],

//     leasing: [
//       { type: "paragraph", content: "Как получить трактор в лизинг?" },
//       {
//         type: "list",
//         content: [
//           "Выбрать лизинговую компанию",
//           "Оставить заявку",
//           "Подготовить необходимые документы ",
//           "Согласовать условия и подписать договор ",
//           "Получить свою технику",
//         ],
//       },
//       { type: "paragraph", content: "Как правило, для юридических лиц:" },
//       {
//         type: "list",
//         content: [
//           "Заявление-анкета.",
//           "Правоустанавливающие документы",
//           "Копия паспорта директора/учредителей",
//           "Бухгалтерская отчетность",
//           "Решение учредителей об одобрении крупной сделки (если она крупная).",
//         ],
//       },
//       { type: "paragraph", content: "Для индивидуальных предпринимателей:" },
//       {
//         type: "list",
//         content: [
//           "Паспорт РФ. Свидетельство об открытии.",
//           "Свидетельство о постановке на учет в налоговых органах.",
//           "Налоговые декларации с отметкой за 2  последних года.",
//           "Справка из банка об обслуживании счета.",
//         ],
//       },
//     ],
//     characteristics: [
//       { column1: "Тяговый класс", column2: 3 },
//       { column1: "Двигатель", column2: " ЯМЗ-238 (236)" },
//       {
//         column1: "Эксплуатационная мощность, л.с.",
//         column2: "176 / 240 кВт /л.с.",
//       },
//       {
//         column1:
//           "Частота вращения коленвала при эксплуатационной мощности, об/мин",
//         column2: 2150,
//       },
//       {
//         column1: "Рабочий объем мотора",
//         column2: "11 л",
//       },
//       {
//         column1: "Полный привод 4х4 ",
//         column2: "есть",
//       },
//       {
//         column1: "Число передач - переднего хода ",
//         column2: "12 ",
//       },
//       {
//         column1:
//           "Число передач - заднего хода (с ходоуменьшителем и реверс-редуктором)",
//         column2: "1 (5,7)",
//       },
//       {
//         column1: "Максимальная скорость вперед",
//         column2: "30 км/час",
//       },
//       {
//         column1: " Максимальная скорость назад ",
//         column2: "10 км/час",
//       },
//       {
//         column1: " Клиренс ",
//         column2: "400 мм",
//       },
//       {
//         column1:
//           "Габаритные размеры, мм - длина (с навесным устройством в транспортном положении)",
//         column2: 6000,
//       },
//       { column1: "Габаритные размеры, мм - ширина", column2: 2406 },
//       { column1: "Габаритные размеры, мм - высота", column2: 3195 },
//       { column1: "Масса эксплуатационная, кг", column2: 8000 },
//       { column1: "Среднее давление на почву, кПа", column2: "130,33" },
//     ],

//     modifications: [
//       { type: "paragraph", content: "Модификации трактора ВЗГМ-150:" },
//       {
//         type: "list",
//         content: [
//           "Оборудован гидравлической навесной системой, валом отбора мощности, прицепной скобой и упряжной серьгой. Установлен предпусковой подогреватель ПБЖ 3000",
//           "Оборудован гидравлическим крюком",
//           "Оборудован бульдозерным отвалом",
//           "Фронтальный погрузчик, оборудован навесным ковшом грузоподъемностью 3 тонны.",
//           "Лесопромышленный, оборудован каркасом безопасности кабины и трелёвочным оборудованием.",
//           "Промышленный вариант, оборудован тягово-сцепным устройством автомобильного типа",
//         ],
//       },
//       { type: "paragraph", content: "Варианты комплектации:" },
//       {
//         type: "list",
//         content: [
//           "Реверс-редуктор",
//           "Ходоуменьшитель",
//           "Задняя гидронавесная система",
//           "БНДТ-10, БНДТ-20 – неповоротный бульдозерный отвал",
//           "БПДТ – поворотный бульдозерный отвал",
//           "БУДТ – универсальный бульдозерный отвал с гидроперекосом",
//         ],
//       },
//       {
//         type: "paragraph",
//         content:
//           "Возможность работы на магистральных дорогах с твёрдым покрытием за счёт колёсного хода и обеспечения габарита по ширине менее 2,5 метра.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Универсальность. Увеличенная база трактора (расстояние между осями передних и задних колёс), установка одинаковых передних и задних колёс увеличенной грузоподъёмности, наличие свободного пространства на задней полураме позволяют существенно увеличить количество агрегатируемых с трактором сельхозмашин",
//       },
//       {
//         type: "list",
//         content: [
//           "Повышение надёжности и долговечности",
//           "Улучшение условий труда",
//           "Повышенные транспортные скорости",
//         ],
//       },
//       {
//         type: "paragraph",
//         content: "",
//       },
//       {
//         type: "paragraph",
//         content: "",
//       },
//     ],
//   },
// ];

export default  tractorData ;
