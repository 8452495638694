import cabin from "../assets/images/spares/Cabin.png";
import engineA41 from "../assets/images/spares/Engine-A-41.png";
import engineYMZ238 from "../assets/images/spares/Engine-YMZ-238.png";
import otval from "../assets/images/spares/Otval.png";
import transmision from "../assets/images/spares/Transmission-DT-75.png";
import gearbox from "../assets/images/spares/gearbox-T-150K.png";
import reverseReductor from "../assets/images/spares/reversReductor.png";
import engineIcon from "../assets/icons/engineIcon.svg";
import powerIcon from "../assets/icons/powerIcon.svg";
import speedIcon from "../assets/icons/speedIcon.svg";
import tankIcon from "../assets/icons/tankIcon.svg";

import otval1 from "../assets/images/spares/Pryamoy-otval-10.webp";
import otval2 from "../assets/images/spares/Pryamoy-otval-20.webp";
import otvalPovorot from "../assets/images/spares/Povorotny-otval.webp";
import otvalUniversal from "../assets/images/spares/Universal-otval.webp";

const sparesData = [
  {
    name: "Дизельный двигатель А-41",
    url: "engine-A41",
    category: "engine",
    price: "480 000 руб.",
    gallery: [
      {
        img: "https://www.vzgm.ru/content/eqipment/engine/engine-A41/red-egine-2.webp ",
        Alt: "Дизельный двигатель",
      },
      {
        img: "https://www.vzgm.ru/content/eqipment/engine/engine-A41/red-egine-3.webp",
        Alt: "Дизельный двигатель",
      },
      {
        img: "https://www.vzgm.ru/content/eqipment/engine/engine-A41/red-egine-4.webp",
        Alt: "Дизельный двигатель",
      },
      {
        img: "https://www.vzgm.ru/content/eqipment/engine/engine-A41/red-egine-5.webp",
        Alt: "Дизельный двигатель",
      },
      {
        img: "https://www.vzgm.ru/content/eqipment/engine/engine-A41/red-egine-5.webp",
        Alt: "Дизельный двигатель",
      },
      {
        img: "https://www.vzgm.ru/content/eqipment/engine/engine-A41/red-egine1.webp",
        Alt: "Дизельный двигатель",
      },
    ],

    img: engineA41,
    pageTitle: " Купить двигатель ДТ-75. Цена 480 000 руб|ВЗГМ",
    pageDescription:
      " Купить двигатель ДТ-75. Каталог двигателей. Доставка от 0 руб. Характеристики двигателя А 41 | ВЗГМ. ",
    alt: "Дизельный двигатель А-41 для трактора",
    position: "regular",
    keySpecs: [
      {
        name: "Engine",
        tagImg: engineIcon,
        description: "А-41 (Д-440-22)",
      },
      {
        name: "Power",
        tagImg: powerIcon,
        description: "90/95/98 л.с.",
      },
      {
        name: "Speed",
        tagImg: speedIcon,
        description: "5,3…11,2 км/ч",
      },
      {
        name: "Tank",
        tagImg: tankIcon,
        description: "175 г/л.с.ч.",
      },
    ],
    shortDescription:
      "Двигатели А-41 — надежность и безотказность, проверенная временем. Отличаются простотой конструкции и удобным размещением на машине. Внедрение немецких технологий позволило создать модели А-41с повышенным ресурсом мотора.",
    fullDescription: [
      {
        type: "paragraph",
        content:
          "Часто нам задают вопрос 'какой двигатель стоит на тракторе Дт75?'. Отвечаем – А-41,  самый распространенный, массовый, ремонтируемый и знакомый всем.  По требованию заказчика можем поставить Д -245 и Д-260.:",
      },

      {
        type: "paragraph",
        content:
          "Четырехцилиндровые дизельные двигатели А-41 разработаны для установки на:",
      },
      {
        type: "list",
        content: [],
      },
      {
        type: "paragraph",
        content:
          "Двигатели предназначены для работы в условиях высоких температур. Предустановленная система охлаждения двигателя успешно справляется с защитой от перегрева, порчи масла и быстрого износа поверхностей рабочих деталей.",
      },
      {
        type: "paragraph",
        content:
          "Во всех моделях реализована раздельные головки цилиндров взамен классическим, что обеспечивает:",
      },
      {
        type: "list",
        content: [
          "Максимальную надежность газового стыка",
          "Уменьшение расхода смазочного материала на угар",
          "Снижение овализации гильзы",
        ],
      },
      {
        type: "paragraph",
        content:
          "Рядное вертикальное расположение цилиндров обеспечивает удобство обслуживания и более рациональное расположение двигателя в тракторе.",
      },
      {
        type: "paragraph",
        content: "По требованию заказчика возможны модификации оборудования:",
      },
      {
        type: "list",
        content: [
          "Генератор 800..3000Вт",
          "Рабочее напряжение 12 или 24 В",
          "Глушитель шума",
          "Муфта сцепления",
          "Предпусковой подогрев воздуха",
        ],
      },
    ],

    characteristics: [
      { column1: "Тип двигателя", column2: "четырехтактный дизель" },
      { column1: "Число цилиндров", column2: 4 },
      { column1: "Порядок работы цилиндров", column2: "1-3-4-2" },
      { column1: "Степень сжатия", column2: "16,5" },
      { column1: "Ресурс дизеля А-41", column2: "не менее 9 тыс. моточасов" },
      {
        column1: "Полезная номинальная мощность",
        column2:
          "номинальная: 69 кВт (94 л.с.); эксплуатационная – 66 кВт (90 л.с.)",
      },

      {
        column1: "Максимальный крутящий момент",
        column2: "от 433 до 482 Н·м, нв зависимости от модификации",
      },
    ],
    advantages: [
      {
        type: "paragraph",
        content:
          "Вы можете купить двигатель ДТ-75 у нас, при этом мы обеспечим:",
      },
      {
        type: "list",
        content: [
          "1. Поставка товара напрямую от поставщика",
          "2. Гарантийное и постгарантийное обслуживание",
          "3. Любой удобный для клиента способ расчета",
          "4. Долгосрочные договора на капремонт и обслуживание",
        ],
      },
    ],
    scope: [
      {
        type: "list",
        content: [
          "Дорожно-строительную спецтехнику",
          "Тракторы типа ДТ-75/ВГЗМ 90 сельскохозяйственного, промышленного назначения",
          "Насосные станции, компрессоры, экскаваторы",
          "Стационарные и передвижные электростанции",
        ],
      },
    ],
  },
  {
    name: "Дизельный двигатель ЯМЗ-238",
    url: "engine-YMZ-238",
    price: "580 000 руб.",
    category: "engine",

    gallery: [
      {
        img: "https://www.vzgm.ru/content/eqipment/engine/engine-YMZ-238/Engine-YMZ-238-1.webp",
        Alt: "Дизельный двигатель ЯМЗ-238",
      },
      {
        img: "https://www.vzgm.ru/content/eqipment/engine/engine-YMZ-238/Engine-YMZ-238-2.webp",
        Alt: "Дизельный двигатель ЯМЗ-238",
      },
      {
        img: "https://www.vzgm.ru/content/eqipment/engine/engine-YMZ-238/Engine-YMZ-238-3.webp",
        Alt: "Дизельный двигатель ЯМЗ-238",
      },
    ],
    img: engineYMZ238,

    pageTitle: " Купить двигатель ЯМЗ-238. Цена 580 000 руб|     ВЗГМ",
    pageDescription:
      " Купить новый двигатель ЯМЗ-238. Каталог двигателей. Доставка от 0 руб. Характеристики двигателя ЯМЗ-238| ВЗГМ. ",
    alt: "Дизельный двигатель ЯМЗ-238 для трактора",
    position: "regular",
    keySpecs: [
      {
        name: "Engine",
        tagImg: engineIcon,
        description: "ЯМЗ-238 (атм.)",
      },
      {
        name: "Power",
        tagImg: powerIcon,
        description: "240 л.с..",
      },
      {
        name: "Speed",
        tagImg: speedIcon,
        description: "2100 об/мин",
      },
      {
        name: "Tank",
        tagImg: tankIcon,
        description: "214 г/л.с.ч.",
      },
    ],
    shortDescription:
      "Купить  двигатель ЯМЗ-238 турбо — значит получить отличную  надежность, большой ресурс и высокую степень унификации базовых моделей и модификаций. Они отличаются простотой конструкции и удобным размещением.",
    fullDescription: [
      {
        type: "paragraph",
        content:
          "Мало какой из дизельных моторов может похвастать таким солидным 'послужным списком', как ЯМЗ-238. Этот двигатель стал 'сердцем' многих тысяч знакомых всем и каждому грузовых автомобилей 'МАЗ', 'КрАЗ', 'Урал'; тракторов 'Кировец' и 'ЧТЗ'; комбайнов 'Дон' и 'Полесье'. А также большого количества всевозможной узкоспециализированной техники, катеров, дизельных электростанций. ЯМЗ-238 чрезвычайно надёжен и неприхотлив в эксплуатации, что и обеспечило ему 'карьеру долгожителя'  мотор продолжает сходить с конвейера Ярославского моторного завода и сегодня. Несмотря на то, что в ассортименте завода появилось немало более современных его 'преемников'",
      },
      {
        type: "paragraph",
        content:
          "Линейка дизельных двигателей ЯМЗ-238 с турбонаддувом экостандарта Евро-0, по своей конструкции является форсированной версией дизелей семейства ЯМЗ-238М2. Но, кроме наличия турбины, в процессе модернизации существенные конструктивные изменения претерпели блок цилиндров, цилиндро-поршневая группа, ТНВД, коленвал и ряд других деталей.",
      },
      {
        type: "paragraph",
        content:
          "Двигатели семейства ЯМЗ-238 турбо востребованныво многом благодаря тому, что данные силовые агрегаты выносливы и неприхотливы, способны безотказно работать в тяжелых температурных условиях от -50 до +50 градусов, при высокой влажности, в разреженной атмосфере высокогорья и пыльных пустынях.",
      },
      {
        type: "paragraph",
        content:
          "Мы закупаем двигатели у производителя Ярославского моторного завода и  устанавливаем на ХТЗ- 150 (ВЗГМ-150). ",
      },

      {
        type: "paragraph",
        content:
          "Двигатели имеют высокую ремонтопригодность, в полном объеме обеспечены запасными частями во всех регионах эксплуатации.",
      },
    ],

    characteristics: [
      { column1: "Тип двигателя", column2: "четырехтактный дизель" },
      { column1: "Число цилиндров", column2: 8 },
      { column1: "Порядок работы цилиндров", column2: "1–5–4–2–6–3–7–8" },
      { column1: "Степень сжатия", column2: "16,5" },
      {
        column1: "Ресурс дизеля А-41",
        column2: "не менее 8 тыс. моточасов или 800 + тыс. км.",
      },
      {
        column1: "Полезная номинальная мощность",
        column2: "240 л.с. ",
      },
      {
        column1: "Максимальный крутящий момент, Н*м (кг*м ",
        column2: "при об./мин. 883 (90) / 1450 ",
      },
    ],

    advantages: [
      {
        type: "paragraph",
        content:
          "Вы можете купить двигатель ЯМЗ-238 у нас, при этом мы обеспечим:",
      },
      {
        type: "list",
        content: [
          "Поставку товара напрямую от поставщика",
          "Гарантийное и постгарантийное обслуживание",
          "Любой удобный способ расчета",
          "Долгосрочные договора на капремонт и обслуживание",
        ],
      },
    ],

    scope: [
      {
        type: "list",
        content: [
          "В технике от автомобилей до автобусов",
          "В тракторах, бульдозерах, комбайнах, экскаваторах",
          "В речных судах",
          "В генераторных станциях",
        ],
      },
    ],
  },
  {
    name: "Кабина",
    url: "cabin",
    category: "spare",
    gallery: [
      {
        img: "https://www.vzgm.ru/content/eqipment/spare/cabin/Cabin-1.webp",
        Alt: "Кабина трактора Дт 75 , люк",
      },
      {
        img: "https://www.vzgm.ru/content/eqipment/spare/cabin/Cabin-2.webp",
        Alt: "Кабина трактора Дт 75 , люк",
      },
      {
        img: "https://www.vzgm.ru/content/eqipment/spare/cabin/Cabin-3.webp",
        Alt: "Кабина трактора Дт 75 , люк",
      },
      {
        img: "https://www.vzgm.ru/content/eqipment/spare/cabin/Cabin-4.webp",
        Alt: "Кабина трактора Дт 75 , люк",
      },
      {
        img: "https://www.vzgm.ru/content/eqipment/spare/cabin/Cabin-5.webp",
        Alt: "Кабина трактора Дт 75 , люк",
      },
      {
        img: "https://www.vzgm.ru/content/eqipment/spare/cabin/Cabin-6.webp",
        Alt: "Кабина трактора Дт 75 , люк",
      },
      {
        img: "https://www.vzgm.ru/content/eqipment/spare/cabin/Cabin-7.webp",
        Alt: "Кабина трактора Дт 75 , люк",
      },
    ],

    price: "300 000 руб.",
    img: cabin,
    pageTitle: " Купить кабину Дт 75. Цена 210 000 руб|     ВЗГМ",
    pageDescription:
      " Купить кабину трактора Дт 75. Каталог запчастей. Доставка от 0 руб| ВЗГМ. ",
    alt: "Кабина ДТ-75 для трактора",

    shortDescription:
      "Кабина трактора ДТ-75  главное место работы механизатора, обеспечивающая защиту, безопасность и комфорт.  Она изготовлена из стального профиля и в ней могут свободно разместиться два человека. Крыша кабины изготовлена из стеклопластика, в крыше установлен люк, который при необходимости может служить аварийным выходом.",
    fullDescription: [
      {
        type: "paragraph",
        content:
          "Остекление кабины выполнено из стеклопакета. Кабина закрытого типа, полностью герметизирована. Стекло кабины Дт-75 изготавливается из классического стекла, триплекса, многослойного. Вес кабины Дт-75 составляет порядка 700 кг.  ",
      },
      {
        type: "paragraph",
        content:
          "На пол устанавливается дополнительная шумоизоляция. Сиденье оператора оборудовано  ремнем безопасности. Комфортную температуру в кабине ДТ-75 зимой поддерживает встроенный отопитель, который подает теплый воздух от водяного радиатора по воздухопроводу. Ну а в летний зной оператор может включить для охлаждения воздуха вентилятор. Таким образом, комфортные условия работы обеспечиваются вне зависимости от погоды и температуры за пределами кабины ДТ-75.",
      },
      {
        type: "paragraph",
        content:
          "За чистоту передних и задних стекол отвечают специальные дворники, причем на лобовом стекле им помогает еще и омыватель. Это позволяет легко удалять снег и грязь, поддерживая отличный обзор даже в непогоду.",
      },
      {
        type: "paragraph",
        content:
          "В кабине в зоне прямой доступности все рычаги, педали и переключатели, в том числе рычаг вала отбора мощности и гидрораспределителя, кнопка электросигнала, переключатель 'массы' аккумуляторной батареи, а также сам аккумулятор, бак с водой, набор инструментов и аптечка. Кабина ДТ-75 смещена от продольной оси трактора вправо, что значительно улучшает обзор для оператора.",
      },
    ],

    characteristics: [
      { column1: "Петля двери нижняя", column2: 2 },
      { column1: "Петля двери верхняя", column2: 2 },
      { column1: "Петля боковины", column2: 4 },
      { column1: "Ось петель", column2: 4 },
      { column1: "Желобок неподвижного стекла двери на стойке", column2: 2 },
      { column1: "Уплотнитель неподвижного стекла двери", column2: 2 },
      { column1: "Желобок опускного стекла двери верхний", column2: 2 },
      { column1: "Желобок опускного стекла двери боковой", column2: 2 },
      { column1: "Уплотнитель опускного стекла двери наружный", column2: 2 },
      { column1: "Амортизатор упора опускного окна", column2: 4 },
      { column1: "Ручка двери", column2: 4 },
      { column1: "Пружина защелки", column2: 2 },
      { column1: "Розетка", column2: 2 },
      { column1: "Стекло переднее левое", column2: null },
      { column1: "Таблица смазки трактора ДТ-75М", column2: 1 },
      { column1: "Шнур распорный заднего окна", column2: 1 },
      { column1: "Стекло заднего окна", column2: 1 },
      { column1: "Стекло переднее правое", column2: 1 },
      { column1: "Заглушка", column2: 3 },
      { column1: "Стекло двери неподвижное", column2: 2 },
      { column1: "Пружина", column2: 1 },
    ],

    advantages: [
      {
        type: "paragraph",
        content:
          "У нас можно купить кабину ДТ-75 в полной  комплектации, полностью оборудованной всеми приборами, остеклением, сиденьями и электропроводкой. На оригинальную кабину ДТ-75 цена значительно ниже, так как поставки идут напрямую от завода-производителя.",
      },
    ],

    scope: [
      {
        type: "list",
        content: ["Для трактора  ВЗГМ – 90", "Для трактора  ДТ-75"],
      },
    ],
  },
  {
    name: "Коробка передач Т-150К",
    url: "gear-T-150K",
    price: "280 000 руб.",
    gallery: [
      {
        img: "https://www.vzgm.ru/content/eqipment/spare/gear-T-150K/gearbox-T-150K-2.webp",
        Alt: "Коробка передач т 150",
      },
      {
        img: "https://www.vzgm.ru/content/eqipment/spare/gear-T-150K/gearbox-T-150K-3.webp",
        Alt: "Коробка передач т 150",
      },
      {
        img: "https://www.vzgm.ru/content/eqipment/spare/gear-T-150K/gearbox-T-150K-4.webp",
        Alt: "Коробка передач т 150",
      },
    ],
    category: "spare",
    img: gearbox,
    position: "span-2",
    pageTitle:
      " Купить коробку передач трактора т 150. Цена 280 000 руб|     ВЗГМ",
    pageDescription:
      " Купить коробку передач трактора Т 150. Каталог запчастей. Доставка от 0 руб| ВЗГМ. ",
    alt: " Коробка передач для трактора Т 150",
    shortDescription:
      " Коробка передач трактора т 150 -гидромеханическая, четырехскоростная с шестернями постоянного зацепления а также персональными гидроподжимными муфтами. Вкупе с двухскоростным редуктором раздаточной КП обеспечивает восемь скоростей движения трактора вперед и четыре назад.",
    fullDescription: [
      {
        type: "paragraph",
        content:
          "Вкупе с двухскоростным редуктором раздаточной КП обеспечивает восемь скоростей движения трактора вперед и четыре назад. Она имеет 4 режима:",
      },
      {
        type: "list",
        content: [
          "режим с замедлением",
          "рабочий",
          "транспортный",
          "режим заднего хода",
        ],
      },
      {
        type: "paragraph",
        content:
          "Дополнительные восемь замедленных скоростей обеспечиваются за счет ходоуменьшителя. Это важно при проведении работ, т.к. при уменьшении показателей скорости растет тяга.",
      },
      {
        type: "paragraph",
        content:
          "Данные скорости необходимы для агрегирования с безмоторными комбайнами и прочими сельскохозяйственными машинами.",
      },
      {
        type: "paragraph",
        content:
          "Преимуществ коробки является ее надежность и долговечность. Компоненты коробки передач изготавливаются из высококачественных материалов, что позволяет добиться высокой точности передачи крутящего момента. Кроме того, гидравлическая система  позволяет улучшить контроль над трактором и сделать его более управляемым. Это обеспечивает максимальную производительность и управляемость трактора во время работы на участке.",
      },

      {
        type: "paragraph",
        content:
          "Важно помнить, что очень важно менять своевременно масло в коробке передач т 150.  От этого зависит долговечность ее работы. Дважды в год, а именно в межсезонье необходимо производить замену масла. Доливать масло нужно при работающем двигателе. Завести, дать прогреться, уровень контролировать через смотровое окно. Масло должно быть одинаковым, как для коробки, так и для редуктора с гидросистемой.    ",
      },
    ],
    characteristics: [
      { column1: "Масло 'лето'", column2: " Дизельное масло М10Г2к " },
      { column1: "Масло 'зима'", column2: " Моторное масло М8Г2к " },
    ],
    advantages: [
      {
        type: "paragraph",
        content:
          "Вы можете купить коробку передач т 150 у нас, вы получаете проверенное качество от официального производителя, а также: ",
      },

      {
        type: "list",
        content: [
          "1. Гарантийное и постгарантийное обслуживание",
          "2. Консультации по работе  течение всего периода эксплуатации ",
        ],
      },
    ],

    scope: [
      {
        type: "list",
        content: [
          "Трактора ХТЗ 150, ВЗГМ-150",
          "Серий и модификаций Т-150, Т-155, Т-156, Т157 ",
        ],
      },
    ],
  },
  {
    name: "Реверс редуктор",
    url: "revers",
    price: "75 000 руб.",
    gallery: [
      {
        img: "https://www.vzgm.ru/content/eqipment/spare/revers/revers-2.webp",
        Alt: "Реверс редуктор Дт 75",
      },
      {
        img: "https://www.vzgm.ru/content/eqipment/spare/revers/revers-3.webp",
        Alt: "Реверс редуктор Дт 75",
      },
      {
        img: "https://www.vzgm.ru/content/eqipment/spare/revers/revers-4.webp",
        Alt: "Реверс редуктор Дт 75",
      },
    ],
    category: "spare",
    img: reverseReductor,
    pageTitle: " Купить реверс редуктор дт 75. Цена 75 000 руб|     ВЗГМ",
    pageDescription:
      " Купить реверс редуктор дт 75. Доставка от 0 руб. Схема реверс редуктора дт 75| ВЗГМ. ",

    alt: "Реверс редуктор для трактора Дт-75",
    position: "regular",
    shortDescription:
      " Реверс редуктор ДТ-75 необходим для изменения направления вращения выходного вала и передачи крутящего момента. Это увеличивает производительность машины и упрощает управление ею. Состоит из корпуса, в котором размещены два ряда шестерен (прямой и реверсивный ход), двух блоков валов и муфты переключения передач. ",
    fullDescription: [
      {
        type: "paragraph",
        content:
          "Реверс редуктор позволяет использовать дополнительные передачи переднего и заднего хода. Он делает возможным перемещение трактора не только на главных, но и на дополнительных скоростях. Например, при передвижении вперед, возможны пониженные реверсивные скорости. При перемещении задним ходом – можно переключаться на реверсивные повышенные скорости.",
      },
      {
        type: "paragraph",
        content:
          " Эффективность их работы соответственно снижается от первой скорости до третьей. Это дает трактору возможность при выполнении различных работ применять необходимые технологические скорости: семь пониженных – при движении вперед, и семь повышенных – при движении задним ходом.",
      },
      {
        type: "paragraph",
        content:
          " Особенности эксплуатации реверс-редуктора ДТ-75. Чтобы продлить срок службы реверс-редуктора ДТ-75, соблюдайте следующие правила:",
      },
      {
        type: "list",
        content: [
          "Выполняйте монтаж с использованием подъемных механизмов, избегайте резких ударов",
          "Регулярно заменяйте масло в редукторе, не реже 1 раза в год",
          "Своевременно меняйте изношенные манжеты и сальники",
          "Проверяйте затяжку крепежа корпуса, при необходимости подтягивайте",
        ],
      },
      {
        type: "paragraph",
        content:
          " При появлении вибраций, шума, течей масла обращайтесь к специалистам. Не запускайте трактор до устранения неисправностей! При правильной эксплуатации ресурс реверс-редуктора ДТ-75 может достигать 10000 моточасов и более.",
      },
      {
        type: "paragraph",
        content:
          "Особенности эксплуатации реверс-редуктора ДТ-75. Для нормальной работы реверс-редуктора ДТ-75 важно использовать рекомендованные заводом-изготовителем сорта масел.  Периодичность замены масла - не реже 1 раза в 1000 моточасов. Фильтр масла также следует менять с периодичностью указанной в инструкции по эксплуатации. Регулярно проверяйте надежность крепления крышки реверс-редуктора, ослабление крепежа может привести к попаданию пыли и грязи внутрь. При необходимости подтягивайте болты и гайки крепления крышки с рекомендуемым моментом затяжки. ",
      },
    ],

    characteristics: [
      { column1: "Масло лето", column2: "Дизельное масло М10Г2к" },
      { column1: "Масло 'зима'", column2: " Моторное масло М8Г2к " },
      { column1: "Передняя передача с передаточным числом ", column2: "1.67" },
      { column1: "Задняя передача с передаточным числом ", column2: "1.35" },
    ],
    advantages: [
      {
        type: "list",
        content: [
          "1. Гарантийное и постгарантийное обслуживание",
          "2. Любой удобный для клиента способ расчета ",
        ],
      },
    ],

    scope: [
      {
        type: "list",
        content: ["Трактора ВЗГМ-90", "модификаций ДТ-75"],
      },
    ],
  },
  {
    name: "Задний мост Дт-75",
    url: "zadniy-most-dt-75",
    price: "230 000 руб.",
    gallery: [
      {
        img: "https://www.vzgm.ru/content/eqipment/spare/transmision/zadnii-most-traktora-dt-75-3.webp",
        Alt: "Задний мост Дт 75",
      },
      {
        img: "https://www.vzgm.ru/content/eqipment/spare/transmision/zadnii-most-traktora-dt-75-2.webp ",
        Alt: "Задний мост Дт 75",
      },
      {
        img: "https://www.vzgm.ru/content/eqipment/spare/transmision/zadnii-most-traktora-dt-75.webp",
        Alt: "Задний мост Дт 75",
      },
    ],
    category: "spare",
    img: transmision,

    pageTitle: " Купить задний мост ДТ-75. Цена 230 000 руб|     ВЗГМ",
    pageDescription:
      " Купить задний мост ДТ-75. Доставка от 0 руб. Схема реверс редуктора дт 75| ВЗГМ. ",

    alt: "Задний мост ДТ-75",
    shortDescription:
      "Задний мост Дт-75 обеспечивает передачу мощности от коробки передач конечным передачам с повышением передаваемого крутящего момента, а также осуществляется поворот трактора и его торможение.",
    fullDescription: [
      {
        type: "paragraph",
        content:
          " Устройство заднего мост Дт 75. Планетарный механизм ДТ-75 работает следующим образом. При движении трактора ДТ-75 по прямой шкивы солнечных шестерен полностью заторможены тормозными лентами, а остановочные тормоза опущены. Вращение от главной передачи ДТ-75 передается коронной шестерне, которая своими внутренними зубчатыми  венцами приводит в движение сателлиты обоих водил.",
      },
      {
        type: "paragraph",
        content:
          " Вращаясь вокруг своих осей, сателлиты одновременно обкатываются вокруг солнечных шестерен, увлекая во вращательное движение водила и связанные с ними валы заднего моста ДТ-75 и ведущие шестерни  конечных передач. При этом  частота вращения водил по сравнению с частотой вращения коронной шестерни уменьшается в 1,42 раза.",
      },

      {
        type: "paragraph",
        content:
          " Для плавного поворота трактора ДТ-75 следует потянуть к себе  рычаг управления тормозом солнечной шестерни той стороны,  в которую совершается поворот. При этом сжимается стяжная пружина тормозной ленты ДТ-75, солнечная шестерня растормаживается и  свободно вращается сателлитами, от чего движение гусеницы ДТ-75 замедляется. Гусеница другой стороны продолжает перемещаться с прежней скоростью, и трактор ДТ-75 плавно поворачивается в сторону отстающей гусеницы.",
      },
      {
        type: "paragraph",
        content:
          " Для крутого поворота трактора ДТ-75 после выключения тормоза планетарного механизма дополнительно нажимают на педаль, производя торможение шкива остановочного тормоза той стороны, в которую совершается поворот. В этом случае движение гусеницы ДТ-75 прекращается, и трактор круто поворачивается в сторону остановленной гусеницы. ",
      },

      {
        type: "paragraph",
        content:
          "Для расширения тяговых возможностей трактора дополнительно можно установить реверсивный редуктор или ходоуменьшитель.",
      },
    ],
    characteristics: [
      { column1: "Скорость максимальная ", column2: "до 9.3 км/ч" },
      { column1: "Скорость минимальная ", column2: "до 0.34 км/ч" },
    ],
    advantages: [
      {
        type: "list",
        content: [
          "1. Гарантийное и постгарантийное обслуживание",
          "2. Любой удобный для клиента способ расчета ",
        ],
      },
    ],
    scope: [
      {
        type: "list",
        content: ["Трактора ВЗГМ-90", "модификаций ДТ-75"],
      },
    ],
  },

  {
    name: "Каретка",
    url: "caretka",
    price: "30 000 руб.",
    gallery: [
      {
        img: "https://www.vzgm.ru/content/eqipment/spare/Karetka/karetka-1.webp",
        Alt: "Каретка Дт 75",
      },
      {
        img: "https://www.vzgm.ru/content/eqipment/spare/Karetka/karetka-2.webp",
        Alt: "Каретка Дт 75",
      },
      {
        img: "https://www.vzgm.ru/content/eqipment/spare/Karetka/karetka-3.webp",
        Alt: "Каретка Дт 75",
      },
      {
        img: "https://www.vzgm.ru/content/eqipment/spare/Karetka/karetka-4.webp",
        Alt: "Каретка Дт 75",
      },
    ],
    category: "spare",
    img: reverseReductor,
    pageTitle: " Купить каретку ДТ-75. Цена 30 000 руб|     ВЗГМ",
    pageDescription:
      " Купить реверс каретку ДТ-75. Доставка от 0 руб. Каретка ДТ-75, каталог. | ВЗГМ. ",

    alt: "Каретка Дт-75",
    position: "regular",
    shortDescription:
      "Каретка трактора ДТ-75 представляет собой тележку, которая состоит из двух балансиров, внутреннего и внешнего. Балансиры соединяются шарнирно при помощи оси качания. К нижней части балансиров укреплены попарно опорные катки. А вверху балансиры распираются цилиндрической пружиной-рессорой. ",
    fullDescription: [
      {
        type: "paragraph",
        content:
          " Внешний балансир ДТ-75 свободно насаживает на цапфу поперечного бруса рамы. Каретка подвески ДТ-75 удерживается от смещения во внешнюю сторону трактора на цапфе.",
      },
      {
        type: "paragraph",
        content:
          " От перемещения по цапфе внутрь трактора каретка ДТ-75 удерживается крышкой, прикрепленной к внешнему балансиру. Для предотвращения вытекания смазки и ее загрязнения к внешнему балансиру крепится корпус уплотнения. ",
      },
      {
        type: "paragraph",
        content:
          " Неподвижность соединения оси качания с внутренним балансиром обеспечивается сборным клином, состоящим из разрезной втулки, круглого конического клина с резьбовым хвостовиком и глухой гайкой. ",
      },
      {
        type: "paragraph",
        content:
          "В нижней части балансиры имеют головку, в расточку которой на роликовых конических подшипниках устанавливается ось катков. На оси напрессовываются стальные опорные катки, закрепленные на оси гайкой и призматической шпонкой. Вытекание смазки и попадание в нее посторонних примесей предотвращается уплотнением на ступице опорного катка. Для защиты от грязи узел защищен дополнительным лабиринтом.",
      },
    ],
    advantages: [
      {
        type: "list",
        content: [
          "Вы можете купить каретку на ДТ-75 у нас при этом мы обеспечим:",
          "1. Гарантийное и постгарантийное обслуживание",
          "2. Любой удобный способ расчета ",
          "3. Оперативную доставку в любой по всей России",
        ],
      },
    ],

    scope: [
      {
        type: "list",
        content: ["Трактора ВЗГМ-90", "модификаций ДТ-75"],
      },
    ],
  },

  {
    name: "Прямой отвал для ДТ-75",
    url: "pryamoy-otval-dlya-dt75",
    gallery: [
      {
        img: "https://www.vzgm.ru/content/eqipment/blade/otval-direct/otval-bndt10-1.webp",
        Alt: "Прямой отвал Дт 75",
      },
      {
        img: "https://www.vzgm.ru/content/eqipment/blade/otval-direct/otval-bndt10-2.webp",
        Alt: "Прямой отвал для Дт 75",
      },
      {
        img: "https://www.vzgm.ru/content/eqipment/blade/otval-direct/otval-bndt10-3.webp",
        Alt: "Прямой отвал Дт 75",
      },
    ],

    price: "245 000 руб.",
    category: "blade",
    img: otval1,
    pageTitle: " Купить прямой отвал ДТ-75. Цена 245 000 руб | ВЗГМ",
    pageDescription:
      " Купить прямой отвал ДТ-75. Доставка от 0 руб. Отвал на ДТ-75. | ВЗГМ. ",

    alt: "Отвал прямой для ДТ-75",
    position: "regular",

    shortDescription:
      " Прямой отвал применяется в дорожно-строительных и других промышленных сферах, в работах по смещению различных видов сыпучего грунта, выравниванию поверхности при планировках, очистке от снега разных территорий, засыпке ям и котлованов и т.д.",
    fullDescription: [
      {
        type: "paragraph",
        content: "Для БНДТ-10 поставляем следующую комплектацию: ",
      },
      {
        type: "list",
        content: [
          "Стальной ковш – основная рабочая часть. Выполняется в трех вариантах – сферической, полусферической или прямой формы. Крепится ковш на передней части рамы бульдозера по обе стороны от двигателя;",
          "Толкание груза осуществляется брусьями, закрепленными шарнирным способом на раме бульдозера;",
          "По обе стороны от двигателя расположены два гидроцилиндра, с помощью них ковш приводится в движение при выполнении работ;",
          "Для предотвращения рассыпания груза во время его перемещения, края отвала прикрыты боковыми щеками;",
          "Защита радиатора  и гидроцилиндров от повреждений во время работы отвала осуществляются дополнительными щитками.",
        ],
      },
    ],
    characteristics: [
      { column1: "Ширина, мм", column2: "2520" },
      { column1: "Высота, мм", column2: "915" },
      { column1: "Заглубление, мм", column2: "300" },
      { column1: "Высота подъема отвала, мм", column2: "650" },
      { column1: "Угол въезда не менее, °", column2: "20" },
      { column1: "Масса конструктивная, кг", column2: "829" },
      { column1: "Ресурс до первого кап ремонта, м/ч", column2: "3200" },
    ],
    advantages: [
      {
        type: "list",
        content: [
          "1. Гарантийное и постгарантийное обслуживание",
          "2. Любой удобный способ расчета ",
        ],
      },
    ],
    scope: [
      {
        type: "list",
        content: ["Трактора ВЗГМ-90", "модификаций ДТ-75"],
      },
    ],
  },
  {
    name: "Прямой отвал БНДТ-20",
    url: "otval-bndt-20",
    gallery: [
      {
        img: "https://www.vzgm.ru/content/eqipment/blade/otval-BNDT-20/bndt-20-1.webp",
        Alt: "Прямой отвал БНДТ-20  Дт 75",
      },
      {
        img: "https://www.vzgm.ru/content/eqipment/blade/otval-BNDT-20/bndt-20-2.webp",
        Alt: " Прямой отвал болотохода Дт 75",
      },
      {
        img: "https://www.vzgm.ru/content/eqipment/blade/otval-BNDT-20/bndt-20-3.webp",
        Alt: " Прямой отвал болотника Дт 75",
      },
    ],

    price: "245 000 руб.",
    category: "blade",
    img: otval2,
    alt: "Отвал прямой для трактора",
    position: "regular",
    shortDescription:
      "БНДТ-20 неповоротный бульдозерный отвал  применяется в дорожно-строительных и других промышленных сферах, в работах по передвиганию и смещению различных видов сыпучего грунта, выравниванию поверхности при планировках, очистке от снега разных территорий, засыпке ям и котлованов и т.д.",
    fullDescription: [
      {
        type: "list",
        content: [
          "Стальной ковш – основная рабочая часть. Выполняется в трех вариантах – сферической, полусферической или прямой формы. Крепится ковш на передней части рамы бульдозера по обе стороны от двигателя;",
          "Толкание груза осуществляется брусьями, закрепленными шарнирным способом на раме бульдозера;",
          "По обе стороны от двигателя расположены два гидроцилиндра, с помощью них ковш приводится в движение при выполнении работ;",
          "Для предотвращения рассыпания груза во время его перемещения, края отвала прикрыты боковыми щеками;",
          "Защита радиатора  и гидроцилиндров от повреждений во время работы отвала осуществляются дополнительными щитками.",
        ],
      },
    ],
    characteristics: [
      { column1: "Ширина, мм", column2: "2520" },
      { column1: "Высота, мм", column2: "915" },
      { column1: "Заглубление, мм", column2: "300" },
      { column1: "Высота подъема отвала, мм", column2: "650" },
      { column1: "Угол въезда не менее, °", column2: "20" },
      { column1: "Масса конструктивная, кг", column2: "829" },
      { column1: "Ресурс до первого кап ремонта, м/ч", column2: "3200" },
    ],
    advantages: [
      {
        type: "list",
        content: [
          "1. Гарантийное и постгарантийное обслуживание",
          "2. Любой удобный способ расчета ",
        ],
      },
    ],
    scope: [
      {
        type: "list",
        content: ["Трактора ВЗГМ-90", "модификаций ДТ-75"],
      },
    ],
  },
  {
    name: "Поворотный отвал БПДТ",
    url: "otval-povorotniy-bpdt",
    gallery: [
      {
        img: "https://www.vzgm.ru/content/eqipment/blade/otval-povorotniy-BPDT/dt-75-s-otv.webp",
        Alt: "Поворотный отвал БПДТ Дт 75",
      },
    ],
    price: "300 000 руб.",
    category: "blade",
    img: otvalPovorot,
    pageTitle: " Купить поворотный отвал ДТ-75. Цена 300 000 руб | ВЗГМ",
    pageDescription:
      " Купить поворотный отвал ДТ-75. Доставка от 0 руб. Отвал на ДТ-75. | ВЗГМ. ",

    alt: "Поворотный отвал для ДТ-75",
    position: "regular",

    shortDescription:
      "Поворотный бульдозерный отвал. Предназначен для установки на трактора ДТ-75. Применяется в дорожно-строительных и других промышленных сферах, в работах по передвиганию и смещению различных видов сыпучего грунта, выравниванию поверхности при планировках, очистке от снега разных территорий, засыпке ям и котлованов и т.д.",

    fullDescription: [
      {
        type: "paragraph",
        content: " Поворотное бульдозерное оборудование состоит из:",
      },
      {
        type: "list",
        content: [
          "поворотного отвала ДТ-75 шарнирно закрепленного на П-образной толкающей раме, подъем, и опускание которой осуществляются двумя гидроцилиндрами управления отвалом расположенными по бокам силовой установки.",
          "соединенных с рамой двумя боковых толкателей внизу и двумя винтовыми раскосами вверху ",
          " угла установки отвала в плане регулируют, меняя место крепления боковых толкателей на раме.",
          " винтовых раскосов, которыми регулируют угол резания ножей отвала",
        ],
      },

      {
        type: "paragraph",
        content: " Особенности поворотного отвала ДТ-75:",
      },
      {
        type: "list",
        content: [
          "Изменяется угол установки отвала ДТ-75 относительно продольной оси трактора на величину ± 25°, что позволяет более производительно использовать агрегат на очистке дорог от снега, обратной отсыпке грунта и др. земляных.",
          "Устанавливается под нужным углом в обе стороны, перпендикулярно или продольно к оси машины",
          " Оснащается как металлическими, так и резиновыми ножами для уборки дорог от слежавшегося или свежевыпавшего снега, грязи, шуги, в легких планировочных работах",
          " Установленный ресурс до первого капитального ремонта: не менее 3200 моточасов.",
        ],
      },

      {
        type: "paragraph",
        content:
          "Он устанавливается на трактор, с гусеничным шасси, спереди. Управление отвалом осуществляется двумя гидроцилиндрами, приводящимися в работу из кабины трактора.",
      },
    ],
    characteristics: [
      { column1: "Ширина, мм", column2: "2520" },
      { column1: "Высота, мм", column2: "915" },
      { column1: "Заглубление, мм", column2: "300" },
      { column1: "Высота подъема отвала, мм", column2: "650" },
      { column1: "Угол въезда не менее, °", column2: "20" },
      { column1: "Масса конструктивная, кг", column2: "829" },
      { column1: "Ресурс до первого кап ремонта, м/ч", column2: "3200" },
    ],
    advantages: [
      {
        type: "list",
        content: [
          "1. Гарантийное и постгарантийное обслуживание",
          "2. Любой удобный  способ расчета ",
        ],
      },
    ],
    scope: [
      {
        type: "list",
        content: ["Трактора ВЗГМ-90", "модификаций ДТ-75"],
      },
    ],
  },
  {
    name: "Универсальный отвал для ДТ-75",
    url: "otval-universalny-dlya-dt75",
    gallery: [
      {
        img: "https://www.vzgm.ru/content/eqipment/blade/otval-universla-BUDT/Buldozer-DT-75-s-uniresalnim-otvalom-7.webp",
        Alt: "Универсальный отвал БУДТ Дт 75",
      },
    ],
    price: "260 000 руб.",
    pageTitle: " Купить универсальный отвал ДТ-75. Цена 260 000 руб|     ВЗГМ",
    pageDescription:
      " Купить  отвал ДТ-75. Доставка от 0 руб. Отвал на ДТ-75. | ВЗГМ. ",
    category: "blade",
    img: otvalUniversal,
    alt: "Универсальный отвал для ДТ-75",
    position: "regular",
    shortDescription:
      " Универсальный бульдозерный отвал. Предназначен для установки на трактора ДТ-75. Применяется в дорожно-строительных и других промышленных сферах, в работах по передвиганию и смещению различных видов сыпучего грунта, выравниванию поверхности при планировках, очистке от снега разных территорий, засыпке ям и котлованов и т.д.",
    fullDescription: [
      {
        type: "paragraph",
        content:
          "Отвальные агрегаты, которые могут регулировать углы поворота и перекоса, и имеют шесть степеней свободы, называются универсальными, поворотными отвалами с гидроперекосом.",
      },

      {
        type: "paragraph",
        content:
          "Он устанавливается на трактор, с гусеничным шасси, спереди. Управление отвалом осуществляется двумя гидроцилиндрами, приводящимися в работу из кабины трактора.",
      },
      {
        type: "paragraph",
        content:
          " Универсальный отвал  выглядит как металлическая сварная конструкция жесткого коробчатого типа, с приваренным к ней лобовым листом из высокопрочной легированной стали. Лист криволинейного профиля состоит из двух частей, скрепленных между собой шарнирами. Такая конструкция делает каждую из частей отвала самостоятельной единицей, которая может изменять свое положение относительно другой части. Это расширяет функциональные возможности универсального отвала по сравнению с отвалами другой конструкции.",
      },
    ],
    characteristics: [
      { column1: "Ширина, мм", column2: "2520" },
      { column1: "Высота, мм", column2: "915" },
      { column1: "Заглубление, мм", column2: "300" },
      { column1: "Высота подъема отвала, мм", column2: "650" },
      { column1: "Угол въезда не менее, °", column2: "20" },
      { column1: "Масса конструктивная, кг", column2: "829" },
      { column1: "Ресурс до первого кап ремонта, м/ч", column2: "3200" },
    ],
    advantages: [
      {
        type: "list",
        content: [
          "1. Гарантийное и постгарантийное обслуживание",
          "2. Любой удобный способ расчета ",
        ],
      },
    ],
    scope: [
      {
        type: "list",
        content: ["Трактора ВЗГМ-90", "модификаций ДТ-75"],
      },
    ],
  },
  {
    name: "Задняя навеска ДТ-75",
    url: "zadniya-naveska-dt75",
    price: "115 000 руб.",

    gallery: [
      {
        img: "https://www.vzgm.ru/content/eqipment/spare/Naveska/Dt-75-s-zadnei-naveskoi-1.webp",
        Alt: "Задняя навеска болотника ДТ-75",
      },
      {
        img: "https://www.vzgm.ru/content/eqipment/spare/Naveska/Dt-75-s-zadnei-naveskoi-2.webp",
        Alt: "Задняя навеска Дт 75",
      },
      {
        img: "https://www.vzgm.ru/content/eqipment/spare/Naveska/zadnyaya-naveska-dt-75-1.webp",
        Alt: "Задняя гидронавеска Дт 75",
      },
      {
        img: "https://www.vzgm.ru/content/eqipment/spare/Naveska/zadnyaya-naveska-dt-75-2.webp",
        Alt: "Задняя навеска болотника Дт 75",
      },
    ],

    pageTitle: " Купить навеску ДТ-75. Цена 115 000 руб | ВЗГМ",
    pageDescription:
      " Купить заднюю навеску ДТ-75. Доставка от 0 руб. Навеска трактора ДТ-75. | ВЗГМ. ",
    category: "blade",
    img: otvalUniversal,
    alt: "Задняя навеска ДТ-75",
    position: "regular",
    shortDescription:
      " Механизм навески служит для присоединения к трактору навесных орудий и установки их в рабочее и транспортное положения.",
    fullDescription: [
      {
        type: "paragraph",
        content:
          "Задняя навеска трактора ДТ-75 установлена сзади на специальных кронштейнах рамы с помощью сварных стоек. На верхней оси свободно вращается полый вал рычагов, на шлицевых концах которого установлены подъемные рычаги. На левом конце вала расположен рычаг штока, свободно вращающийся на валу. ",
      },
      {
        type: "paragraph",
        content:
          "Если золотник распределителя установлен в положение 'подъем', поршень силового цилиндра под давлением масла действует через шток на рычаг штока, который свободно поворачивается до тех пор, пока его опорная площадка не упрется в площадку на нижней стороне левого подъемного рычага. После этого будут поворачиваться подъемные рычаги, которые раскосами поднимают нижние тяги  вместе с орудием в транспортное положение.",
      },

      {
        type: "paragraph",
        content:
          "Вы можете купить заднюю навеску на трактор ДТ-75 у нас, при этом мы обеспечим:",
      },
    ],

    advantages: [
      {
        type: "list",
        content: [
          "1. Гарантийное и постгарантийное обслуживание",
          "2. Любой удобный способ расчета ",
          "3. Оперативную доставку по территории РФ",
        ],
      },
    ],
    scope: [
      {
        type: "list",
        content: ["Трактора ВЗГМ-90", "модификаций ДТ-75"],
      },
    ],
  },
];

export default sparesData;
