import { useState } from "react";

const WhatsAppIcon = ({ size = 41 }) => {
  const [hover, setHover] = useState(false);

  return (
    <a
      href="https://wa.me/79023868000"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      target="_blank"
    >
      <svg
        viewBox="0 0 48 48"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        //xmlns:xlink="http://www.w3.org/1999/xlink"
        fill="#000000"
        width={size}
        height={size}
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
          {" "}
          <title>Whatsapp-color</title> <desc>Created with Sketch.</desc>{" "}
          <defs> </defs>{" "}
          <g
            id="Icons"
            stroke="none"
            strokeWidth="1"
            fill="#FFFFFF"
            fillRule="evenodd"
          >
            {" "}
            <g
              id="Color-"
              transform="translate(-700.000000, -360.000000)"
              fill="#67C15E"
            >
              {" "}
              <path
                d="M723.993033,360 C710.762252,360 700,370.765287 700,383.999801 C700,389.248451 701.692661,394.116025 704.570026,398.066947 L701.579605,406.983798 L710.804449,404.035539 C714.598605,406.546975 719.126434,408 724.006967,408 C737.237748,408 748,397.234315 748,384.000199 C748,370.765685 737.237748,360.000398 724.006967,360.000398 L723.993033,360.000398 L723.993033,360 Z M717.29285,372.190836 C716.827488,371.07628 716.474784,371.034071 715.769774,371.005401 C715.529728,370.991464 715.262214,370.977527 714.96564,370.977527 C714.04845,370.977527 713.089462,371.245514 712.511043,371.838033 C711.806033,372.557577 710.056843,374.23638 710.056843,377.679202 C710.056843,381.122023 712.567571,384.451756 712.905944,384.917648 C713.258648,385.382743 717.800808,392.55031 724.853297,395.471492 C730.368379,397.757149 732.00491,397.545307 733.260074,397.27732 C735.093658,396.882308 737.393002,395.527239 737.971421,393.891043 C738.54984,392.25405 738.54984,390.857171 738.380255,390.560912 C738.211068,390.264652 737.745308,390.095816 737.040298,389.742615 C736.335288,389.389811 732.90737,387.696673 732.25849,387.470894 C731.623543,387.231179 731.017259,387.315995 730.537963,387.99333 C729.860819,388.938653 729.198006,389.89831 728.661785,390.476494 C728.238619,390.928051 727.547144,390.984595 726.969123,390.744481 C726.193254,390.420348 724.021298,389.657798 721.340985,387.273388 C719.267356,385.42535 717.856938,383.125756 717.448104,382.434484 C717.038871,381.729275 717.405907,381.319529 717.729948,380.938852 C718.082653,380.501232 718.421026,380.191036 718.77373,379.781688 C719.126434,379.372738 719.323884,379.160897 719.549599,378.681068 C719.789645,378.215575 719.62006,377.735746 719.450874,377.382942 C719.281687,377.030139 717.871269,373.587317 717.29285,372.190836 Z"
                id="Whatsapp"
                className={`${
                  hover ? "fill-color_accent_yellow duration-200" : ""
                }`}
              >
                {" "}
              </path>{" "}
            </g>{" "}
          </g>{" "}
        </g>
      </svg>
    </a>

    // <div>
    //   <a
    //     href="https://wa.me/79023868000"
    //     onMouseEnter={() => setHover(true)}
    //     onMouseLeave={() => setHover(false)}
    //     target="_blank"
    //   >
    //     <svg
    //       width={size}
    //       height={size}
    //       viewBox="0 0 41 41"
    //       fill="none"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       {/* <circle
    //         cx="20.25"
    //         cy="20.25"
    //         r="20.25"
    //         className={hover ? "fill-color_dark duration-200" : ""}
    //       /> */}
    //       <path
    //         d="M28.2759 12.2131C26.2756 10.2197 23.6085 9.1123 20.7933 9.1123C14.9405 9.1123 10.1991 13.8373 10.1991 19.6697C10.1991 21.5154 10.7177 23.3611 11.6067 24.9115L10.125 30.3748L15.7555 28.8982C17.3113 29.7104 19.0152 30.1533 20.7933 30.1533C26.646 30.1533 31.3875 25.4283 31.3875 19.5959C31.3134 16.8643 30.2762 14.2064 28.2759 12.2131ZM25.9052 23.435C25.6829 24.0256 24.6457 24.6162 24.1271 24.69C23.6826 24.7639 23.0899 24.7639 22.4973 24.6162C22.1268 24.4686 21.6082 24.3209 21.0155 24.0256C18.3485 22.9182 16.6445 20.2604 16.4963 20.0389C16.3482 19.8912 15.3851 18.6361 15.3851 17.3072C15.3851 15.9783 16.0518 15.3877 16.2741 15.0924C16.4963 14.7971 16.7927 14.7971 17.0149 14.7971C17.1631 14.7971 17.3854 14.7971 17.5335 14.7971C17.6817 14.7971 17.904 14.7232 18.1262 15.24C18.3485 15.7568 18.8671 17.0857 18.9412 17.1596C19.0152 17.3072 19.0152 17.4549 18.9412 17.6025C18.8671 17.7502 18.793 17.8979 18.6448 18.0455C18.4966 18.1932 18.3485 18.4146 18.2744 18.4885C18.1262 18.6361 17.978 18.7838 18.1262 19.0053C18.2744 19.3006 18.793 20.1127 19.6079 20.851C20.6451 21.7369 21.4601 22.0322 21.7564 22.1799C22.0527 22.3275 22.2009 22.2537 22.3491 22.1061C22.4973 21.9584 23.0159 21.3678 23.164 21.0725C23.3122 20.7772 23.5344 20.851 23.7567 20.9248C23.979 20.9986 25.3125 21.6631 25.5348 21.8107C25.8311 21.9584 25.9793 22.0322 26.0534 22.1061C26.1274 22.3275 26.1274 22.8443 25.9052 23.435Z"
    //         className={`${
    //           hover
    //             ? "fill-color_white duration-200"
    //             : "fill-color_dark duration-200 fill-color_whatsapp_green duration-200"
    //         }`}
    //       />
    //     </svg>
    //   </a>
    // </div>
  );
};

export default WhatsAppIcon;
